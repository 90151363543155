import { Modal, Form, Input, notification, Button } from "antd";
import { createUser, insertRole } from "../../../helpers/helper";

const ModalAddEdit = ({ isOpen, onClose, selectedRole }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const handleOk = async () => {
    try {
      const result = await createUser({
        userName: form.getFieldValue("userName"),
        fullName: form.getFieldValue("fullName"),
        password: form.getFieldValue("pwd"),
      });
      api["success"]({
        message: "Tạo thành công",
        description: "Tạo người dùng thành công",
      });
      onClose();
      form.resetFields();
    } catch (error) {
      console.log(error);
      return api["error"]({
        message: "Lỗi",
        description: error.response?.data?.message,
      });
    }
  };
  return (
    <Modal open={isOpen} onCancel={onClose} footer={null}>
      {contextHolder}
      <Form form={form} style={{ margin: "20px 10px" }} onFinish={handleOk}>
        <Form.Item
          rules={[{ required: true, message: "Vui lòng nhập họ tên" }]}
          name="fullName"
          label="Tên người dùng"
        >
          <Input placeholder="Nhập tên người dùng" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Vui lòng nhập tài khoản" }]}
          name="userName"
          label="Tài khoản"
        >
          <Input placeholder="Nhập tài khoản" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
          name="pwd"
          label="Mật khẩu"
        >
          <Input placeholder="Nhập mật khẩu" type="password" />
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "end" }}>
          <Button htmlType="submit" type="primary">
            Thêm người dùng
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddEdit;
