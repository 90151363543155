import { Checkbox, Modal } from "antd";
import { useEffect, useState } from "react";

const ModalPermission = ({ isOpen, onClose, selectedUser, onOk, room }) => {
  const [thisRoom, setThisRoom] = useState(room);

  useEffect(() => {
    setThisRoom(room);
  }, [room]);
  return (
    <Modal
      title="Phân quyền nhóm"
      open={isOpen}
      onCancel={onClose}
      onOk={onOk}
      room={room}
    >
      <div>
        <Checkbox
          checked={thisRoom.canDeleteUser?.includes(selectedUser?._id)}
          onChange={(e) => {
            if (e.target.checked) {
              setThisRoom({
                ...thisRoom,
                canDeleteUser: [...thisRoom.canDeleteUser, selectedUser?._id],
              });
            } else {
              setThisRoom({
                ...thisRoom,
                canDeleteUser: thisRoom.canDeleteUser?.filter(
                  (item) => item != selectedUser?._id
                ),
              });
            }
          }}
        />{" "}
        Xóa người dùng
      </div>
      <div>
        <Checkbox
          checked={thisRoom.canBan?.includes(selectedUser?._id)}
          onChange={(e) => {
            if (e.target.checked) {
              setThisRoom({
                ...thisRoom,
                canBan: [...thisRoom.canBan, selectedUser?._id],
              });
            } else {
              setThisRoom({
                ...thisRoom,
                canBan: thisRoom.canBan?.filter(
                  (item) => item != selectedUser?._id
                ),
              });
            }
          }}
        />{" "}
        Ban người dùng
      </div>
      <div>
        <Checkbox
          checked={thisRoom.canDeleteMessage?.includes(selectedUser?._id)}
          onChange={(e) => {
            if (e.target.checked) {
              setThisRoom({
                ...thisRoom,
                canDeleteMessage: [
                  ...thisRoom.canDeleteMessage,
                  selectedUser?._id,
                ],
              });
            } else {
              setThisRoom({
                ...thisRoom,
                canDeleteMessage: thisRoom.canDeleteMessage?.filter(
                  (item) => item != selectedUser?._id
                ),
              });
            }
          }}
        />{" "}
        Xóa tin nhắn
      </div>
    </Modal>
  );
};

export default ModalPermission;
