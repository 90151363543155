import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';



export default function RoomName({ room, user }) {
	const [onlineMembers, setOnlineMembers] = useState([]);

	const onlineUsers = useSelector((state) => state.onlineUsers);

	React.useEffect(() => {
		setOnlineMembers(onlineUsers);
	}, [onlineUsers]);

	return (
		<>
			{
				(room.roomType === 'personal') ? (
					<div className='w-50'>
						<h6 className='m-0 allow-one-line fw-bold'>
							{
								room.members.filter(x => x.user._id !== user._id)[0].user.fullName
							}
						</h6>
						{
							(onlineMembers.filter(x => x.user?._id === room.members.filter(x => x.user._id !== user._id)[0].user._id).length > 0) ? (
								<small className='text-primary d-block'>online</small>
							) : (
								<small className='text-muted d-block'>offline</small>
							)
						}
					</div>
				) : (
					<div className='w-50'>
						<h6 className='m-0 allow-one-line fw-bold'>{room.roomName}</h6>
						{
							(room.members?.length !== undefined) ? (
								<small className='text-muted d-block'>
									({room.members?.filter(x => x?.user?.userName !== 'admin').length} thành viên)
								</small>
							) : (<></>)
						}
					</div>
				)
			}
		</>
	);
}