const checkRoomPermission = (roomPermission = [], owner) => {
  const user = JSON.parse(sessionStorage.getItem("infoUsers") || "null");
  const userId = user?.user?._id;

  return (
    roomPermission.find((item) => item == userId) ||
    user?.user?.role?.permission?.find(
      (item) =>
        item.permissionFieldName == "manage" && item.permissionFunction == "all"
    ) ||
    owner == user?.user?._id
  );
};

export default checkRoomPermission;
