export class Chat {
  constructor(
    user,
    message,
    room,
    type,
    createdTime,
    _id,
    lastModified,
    replyTo,
    file,
    forwardedFrom
  ) {
    this._id = _id;
    this.user = user;
    this.message = message;
    this.room = room;
    this.type = type;
    this.createdTime = createdTime;
    this.lastModified = lastModified;
    this.replyTo = replyTo;
    this.file = file;
    this.forwardedFrom = forwardedFrom;
  }
}
