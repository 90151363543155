import React from "react";
import Route from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/animation.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  return (
    <>
      <Provider store={store}>
        <Route />
      </Provider>
    </>
  );
}

export default App;
