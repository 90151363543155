import {
  Button,
  Input,
  Pagination,
  Popconfirm,
  Table,
  Tooltip,
  notification,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { BsEye, BsSearch, BsTrash } from "react-icons/bs";
import {
  deleteRole,
  deleteRoom,
  getPagingRole,
  getPagingRoom,
} from "../../helpers/helper";
import { BiEdit } from "react-icons/bi";
import ModalAddEdit from "./ModalAddEdit";
import { useNavigate } from "react-router-dom";

const RoomManagement = () => {
  const [rooms, setRooms] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [showModalAddEdit, setShowModalAddEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const inputRef = useRef();
  const [selectedRoom, setSelectedRoom] = useState({});
  const navigate = useNavigate();

  const clearSelectedRole = () => {
    setSelectedRoom({});
  };

  const getRooms = async () => {
    try {
      const result = await getPagingRoom({ pageSize, pageIndex, search });
      setRooms(result.data);
      setPageSize(Number(result.pageSize));
      setPageIndex(Number(result.pageIndex));
      setCount(result.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelteRoom = async (id) => {
    try {
      const result = await deleteRoom(id);
      api["success"]({
        message: "Xóa thành công",
        description: "Xóa room thành công",
      });
      setRooms(rooms.filter((role) => role._id != id));
    } catch (error) {
      console.log(error);
      api["error"]({
        message: "Xóa thất bại",
        description: "Xóa room thất bại",
      });
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (_, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Tên phòng chat",
      dataIndex: "roomName",
      key: "name",
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <Tooltip title="Xóa phòng chat">
              <Popconfirm
                title="Xóa phòng chat"
                description="Bạn có muốn xóa phòng chat này?"
                okText="OK"
                cancelText="No"
                onConfirm={() => handleDelteRoom(record?._id)}
              >
                <BsTrash
                  style={{ marginLeft: "3px", cursor: "pointer" }}
                  size={15}
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Xem phòng chat">
              <BsEye
                onClick={() => {
                  navigate(`/manage-room-detail/${record?._id}`);
                }}
                style={{ marginLeft: "3px", cursor: "pointer" }}
                size={16}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    getRooms();
  }, [pageSize, pageIndex, search]);
  return (
    <div>
      {contextHolder}
      <div style={{ marginTop: "15px" }}>
        <Input
          style={{ maxWidth: "300px" }}
          placeholder="Nhập tên phòng chat..."
          ref={inputRef}
        />
        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            setSearch(inputRef.current?.input?.value);
          }}
        >
          <BsSearch />
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => setShowModalAddEdit(true)}
        >
          Thêm phòng chat
        </Button>
      </div>
      <div>
        <Table
          style={{ marginTop: "15px" }}
          dataSource={rooms}
          columns={columns}
          pagination={false}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Pagination
          style={{ marginTop: "15px" }}
          current={pageIndex}
          total={count}
          onChange={(page, pageSize) => {
            setPageIndex(page);
            setPageSize(pageSize);
          }}
        />
      </div>
      <ModalAddEdit
        isOpen={showModalAddEdit}
        onClose={() => {
          setShowModalAddEdit(false);
          getRooms();
          clearSelectedRole();
        }}
        clearSelectedRole={clearSelectedRole}
        selectedRoom={selectedRoom}
      />
    </div>
  );
};

export default RoomManagement;
