import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
    name: 'logedInUser',
    initialState: null,
    reducers: {
        setLogedInUser: (state, action) => {
            state = action.payload
            return state
        }
    }
})

const { reducer, actions } = slice;
export const { setLogedInUser } = actions;
export default reducer;