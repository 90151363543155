/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.css";
import { io } from "socket.io-client";
import EmbedChats from "./EmbedChats";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import { useParams } from "react-router";

export class PagedModel {
  constructor(pageIndex, pageSize, totalPages, data) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.totalPages = totalPages;
    this.data = data;
  }
  pageIndex;
  pageSize;
  totalPages;
  data;
}

export const MessageTypes = {
  NOTIFICATION: "notification",
  MESSAGE: "message",
  IMAGE: "image",
  VIDEO: "video",
  GIF: "gif",
  VUVUZELA: "vuvuzela",
  BOUQUET: "bouquet",
  FIREWORKS: "fireworks",
  ANIMATION_GIFT: "ANIMATION_GIFT",
  MESSAGE_PIN: "MESSAGE_PIN",
};

const socket = io(
  process.env.WEBSOCKET_SERVER ?? "https://socket.truonggasavan.online",
  {
    autoConnect: true,
  }
);

const TOAST_LIMIT = 2;

const textJoinRoom = "đã tham gia phòng trò chuyện";

export default function EmbedRoom(props) {
  const router = useParams();
  toastr.options.positionClass = "toast-top-center";
  toastr.options.toastClass = "toast-class";
  const { confettiWidth, confittiHeight } = useWindowSize();
  let roomId = router.roomId;

  const [pageIndex, setPageIndex] = useState(1);
  const [logedInUser, setLogedInUser] = useState(null);
  const [pagedModel, setPagedModel] = useState(new PagedModel());
  const [isShowSignUp, setIsShowSignUp] = useState(false);
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [dataToReply, setDataToReply] = useState(null);
  const [dataPin, setDataPin] = useState(null);
  const [isShowConfetti, setIsShowConfetti] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { toasts } = useToasterStore();

  const [phoneNumberNotification, setPhoneNumberNotification] = useState({
    status: 0,
    message: "",
  });

  const newMessageRef = useRef(null);
  const messageRefs = useRef(null);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  useEffect(() => {
    const fetchData = async () => {
      if (roomId) {
        socket.emit("join_room", {
          room: { _id: roomId },
          user: logedInUser?.user,
        });

        await axios
          .get(
            `/api/chat/getPaging?room=${roomId}&pageIndex=${pageIndex}&pageSize=200`
          )
          .then(async (response) => {
            let sortedMessages = response.data
              .filter(
                (x) =>
                  x.type === MessageTypes.MESSAGE ||
                  x.type === MessageTypes.NOTIFICATION ||
                  x.type === MessageTypes.MESSAGE_PIN
              )
              .sort((a, b) => {
                if (a.createdTime < b.createdTime) {
                  return -1;
                }
                if (a.createdTime > b.createdTime) {
                  return 1;
                }
                return 0;
              });
            response.data = sortedMessages;
            setPagedModel(response);

            setTimeout(() => {
              scrollToNewMessage();
            }, 300);
          });

        let localStorageUser = localStorage.getItem("logedInUser");
        if (localStorageUser) {
          let logedInUser = JSON.parse(localStorageUser);
          let now = new Date();
          let expiredAt = new Date(logedInUser.expiredAt);
          if (now <= expiredAt) {
            //socket.connect();
            socket.emit("user_connect", logedInUser.user);
            setLogedInUser(logedInUser);
          } else {
            setIsShowSignUp(true);
          }
        }
      }
    };

    fetchData();
  }, [roomId, refetch]);

  useEffect(() => {
    const intervalMessage = setInterval(() => {
      setRefetch((state) => !state);
    }, 11000);

    return () => clearInterval(intervalMessage);
  }, []);

  useEffect(() => {
    socket.on("receive_message", async (data) => {
      if (
        roomId === data.room._id &&
        (data.type === MessageTypes.MESSAGE ||
          data.type === MessageTypes.NOTIFICATION ||
          data.type === MessageTypes.MESSAGE_PIN)
      ) {
        setPagedModel((old) => {
          return { ...old, data: [...old.data, data] };
        });
        if (data.user?._id === logedInUser?.user?._id) {
          setTimeout(() => {
            scrollToNewMessage();
          }, 300);
        } else {
          let scrollHeight = messageContainerRef.current?.scrollHeight ?? 0;
          let scrollTop = messageContainerRef.current?.scrollTop ?? 0;
          let bottom =
            scrollHeight - scrollTop ===
            messageContainerRef.current?.clientHeight;
          if (bottom === true) {
            setTimeout(() => {
              scrollToNewMessage();
            }, 300);
          } else {
            if (!showScrollToBottomButton) {
              setShowScrollToBottomButton(true);
            }
          }
        }
      } else if (data.type === MessageTypes.VUVUZELA) {
        setIsShowConfetti(true);
        let ringtone = new Audio("/audios/vuvuzela.mp3");
        ringtone.play();
        setTimeout(() => {
          setIsShowConfetti(false);
        }, 7000);
      } else if (data.type === MessageTypes.ANIMATION_GIFT) {
        switch (data.message) {
          case "animationGiftLV1":
            toast((t) => (
              <>
                <div className={"animationGift"}>
                  <span className={"animationGiftFullName"}>
                    {data?.user?.user?.fullName} đã tặng moto cho BLV
                  </span>
                  <image
                    src="/embed/gif/MOTO_GIF.gif"
                    alt=""
                    width={250}
                    height={150}
                  />
                </div>
              </>
            ));
            break;
          case "animationGiftLV2":
            toast((t) => (
              <>
                <div className={"animationGift"}>
                  <span className={"animationGiftFullName"}>
                    {data?.user?.user?.fullName} đã tặng siêu xe cho BLV
                  </span>
                  <image
                    src="/embed/gif/XE_GIF.gif"
                    alt=""
                    width={250}
                    height={150}
                  />
                </div>
              </>
            ));
            break;
          case "animationGiftLV3":
            toast((t) => (
              <>
                <div className={"animationGift"}>
                  <span className={"animationGiftFullName"}>
                    {data?.user?.user?.fullName} đã tặng chuyên cơ cho BLV
                  </span>
                  <image
                    src="/embed/gif/MAYBAY.gif"
                    alt=""
                    width={250}
                    height={150}
                  />
                </div>
              </>
            ));
            break;
          case "animationGiftLV4":
            toast((t) => (
              <>
                <div className={"animationGift"}>
                  <span className={"animationGiftFullName"}>
                    {data?.user?.user?.fullName} đã tặng du thuyền cho BLV
                  </span>
                  <image
                    src="/embed/gif/TAU_GIF.gif"
                    alt=""
                    width={250}
                    height={150}
                  />
                </div>
              </>
            ));
            break;
          case "animationGiftLV5":
            toast((t) => (
              <>
                <div className={"animationGift"}>
                  <span className={"animationGiftFullName"}>
                    {data?.user?.user?.fullName} đã tặng tên lửa cho BLV
                  </span>
                  <image
                    src="/embed/gif/ROCKET_GIF.gif"
                    alt=""
                    width={250}
                    height={150}
                  />
                </div>
              </>
            ));
            break;
          default:
            break;
        }
      } else if (data.type === MessageTypes.MESSAGE_PIN) {
        setDataPin(data);
      }
    });

    socket.on("receive_edited_message", (data) => {
      setPagedModel((old) => {
        let newMessages = old.data.map((x) => {
          if (x._id === data._id) {
            x = data;
          }
          return x;
        });
        return { ...old, data: [...newMessages] };
      });
    });

    socket.on("deleted_message", async (data) => {
      if (data) {
        setPagedModel((old) => {
          return {
            ...old,
            data: old.data.filter((x) => x._id !== data._id),
          };
        });
      }
    });

    return () => {
      socket.off("receive_message");
      socket.off("receive_edited_message");
      socket.off("deleted_message");
    };
  }, [socket, roomId]);

  function scrollToNewMessage() {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
    // newMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    setShowScrollToBottomButton(false);
  }

  async function signUp(event) {
    event.preventDefault();
    const fullName = event.target.fullName.value;
    const userName = event.target.userName.value;
    const password = event.target.password.value;
    // const phoneNumber = event.target.phoneNumber.value;

    // event.target.button.setAttribute("disabled", "disabled");
    if (userName && fullName && password) {
      var usernameRegex =
        /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;
      if (!usernameRegex.test(userName)) {
        toastr.warning(
          "Tên tài khoản không được phép chứa khoảng trắng và các ký tự đặc biệt!"
        );
        return;
      }

      const user = {
        userName: userName,
        password: password,
        fullName: fullName,
        roomId: roomId,
      };

      try {
        const response = await axios.post("/api/user/register", user);
        if (response.status === 1) {
          const loginRes = await axios.post(`/api/user/login`, {
            userName: user.userName,
            password: user.password,
          });
          if (loginRes.status === 1) {
            let logedInUser = {
              _id: loginRes.data.user._id,
              fullName: loginRes.data.user.fullName,
              avatar: loginRes.data.user.avatar,
              level: loginRes.data.user.level,
              role: loginRes.data.user.role,
            };

            let dataToStore = {
              user: logedInUser,
              token: loginRes.data.token,
              expiredAt: loginRes.data.expiredAt,
            };

            localStorage.setItem("logedInUser", JSON.stringify(dataToStore));

            socket.emit("user_connect", logedInUser);
            setLogedInUser(dataToStore);

            let dataToInsert = {
              message: `${fullName} ${textJoinRoom}`,
              room: roomId,
              user: logedInUser,
              type: MessageTypes.NOTIFICATION,
            };
            let savedMessageRes = await axios.post(
              `/api/chat/anonymousInsert`,
              dataToInsert
            );

            toastr.success("Đăng ký thành công!");
            setIsShowSignUp(false);
          }
        } else if (response.data.status === 0) {
          toastr.error("Tài khoản đã tồn tại!");
        }
      } catch (error) {
        if (error.response?.status === 400) {
          toastr.error("Tên đăng nhập không được là admin!");
        }
      }
    } else {
      toastr.warning("Bạn cần nhập đủ thông tin!");
    }
  }

  async function login(event) {
    event.preventDefault();
    const userName = event.target.userName.value;
    const password = event.target.password.value;

    if (userName && password) {
      try {
        const response = await axios.post(`/api/user/login`, {
          userName: userName,
          password: password,
        });
        console.log(response);
        if (response.status === 1) {
          let logedInUser = {
            _id: response.data.user._id,
            fullName: response.data.user.fullName,
            avatar: response.data.user.avatar,
            level: response.data.user.level,
            role: response.data.user.role,
          };
          let dataToStore = {
            user: logedInUser,
            token: response.data.token,
            expiredAt: response.data.expiredAt,
          };
          localStorage.setItem("logedInUser", JSON.stringify(dataToStore));
          socket.connect();
          socket.emit("user_connect", logedInUser);
          socket.emit("join_room", {
            room: { _id: roomId },
            user: logedInUser,
          });

          let dataToInsert = {
            message: `${logedInUser.fullName} ${textJoinRoom}`,
            room: roomId,
            user: logedInUser,
            type: MessageTypes.NOTIFICATION,
          };
          let savedMessageRes = await axios.post(
            `/api/chat/anonymousInsert`,
            dataToInsert
          );

          setLogedInUser(dataToStore);
          setIsShowLogin(false);
        } else if (response.status === 2) {
          toastr.error("Sai mật khẩu!");
        } else if (response.status === 3) {
          toastr.error("Tài khoản đã bị khóa!");
        } else if (response.status === 4) {
          toastr.error("Tài khoản không tồn tại!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function jumpToMessage(messageId) {
    if (messageId) {
      messageRefs.current.forEach((messageRef, index) => {
        if (messageRef) {
          let messageItemData = JSON.parse(
            messageRef.getAttribute("message-item")
          );
          if (messageItemData._id === messageId) {
            if (index - 4 > 0) {
              messageRefs.current[index - 4]?.scrollIntoView({
                behavior: "smooth",
              });
            } else {
              messageRefs.current[index]?.scrollIntoView({
                behavior: "smooth",
              });
            }
            messageRefs.current[index]?.classList.add("highlightMessage");
            setTimeout(() => {
              messageRefs.current[index]?.classList.remove("highlightMessage");
            }, 1000);
          }
        }
      });
    }
  }

  async function handlePinMessage(data) {
    try {
      setIsShowLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + logedInUser?.token,
      };

      let dataToInsert = {};
      dataToInsert = {
        message:
          data.user.level + "||" + data.user.fullName + "||" + data.message,
        room: roomId,
        type: MessageTypes.MESSAGE_PIN,
      };

      let response = await axios.post(`/api/chat/insert`, dataToInsert, {
        headers: headers,
      });
      if (response.data.status === 1) {
        socket.emit("send_message", response.data.data);
      }
      setIsShowLoading(false);
    } catch (e) {
      console.log(e);
      setIsShowLoading(false);
    }
  }

  function renderMessagePin(dataPin) {
    const arrDataPin = dataPin.message.split("||");
    const level = arrDataPin[0];
    const fullName = arrDataPin[1];
    const mess = arrDataPin[2];

    return (
      <>
        <p className="m-0">
          Bình luận đã ghim{" "}
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pin"
              viewBox="0 0 16 16"
            >
              <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282z" />
            </svg>
          </span>
        </p>
        <div className={"messagePinItem"}>
          <div>
            <p className="m-0">
              <span className={"fw-bold fullName"}>
                <span
                  className={`badgeA2zchat ${
                    level === 1
                      ? "lv1"
                      : level === 2
                      ? "lv2"
                      : level === 3
                      ? "lv3"
                      : level === 4
                      ? "lv4"
                      : level === 5
                      ? "lv5"
                      : "lv1"
                  }`}
                >{`Lv.${level}`}</span>
                {`${fullName?.toString().trim()}: `}
              </span>
              {!dataPin.replyTo ? mess : ""}
            </p>
          </div>
          {logedInUser?.user?.role?.roleName === "Admin" && (
            <div className="ms-2 me-2">
              <button
                className="btn-close"
                style={{ fontSize: "10px" }}
                onClick={async () => {
                  try {
                    setIsShowLoading(true);
                    let response = await axios.delete(
                      `/api/chat/delete/${dataPin._id}`
                    );
                    if (response.status === 1)
                      socket.emit("delete_message", dataPin);
                    setIsShowLoading(false);
                  } catch (e) {
                    setIsShowLoading(false);
                  }
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="embedContainer">
        <Toaster
          position="top-center"
          reverseOrder={true}
          gutter={200}
          toastOptions={{
            duration: 3000,
            className: "itemToastGift",
            style: {
              width: "100%",
              height: "100%",
              background: "transparent",
              boxShadow: "unset",
              padding: 0,
            },
          }}
        />
        {pagedModel.data ? (
          <div className="messagesContainer" ref={messageContainerRef}>
            {pagedModel.data
              .filter((item) => item.type === MessageTypes.MESSAGE_PIN)
              .map((data, i, row) => {
                if (i === row.length - 1) {
                  return (
                    <>
                      <div className={"messagePinContainer"}>
                        {renderMessagePin(data)}
                      </div>
                    </>
                  );
                }
              })}
            {pagedModel.data.map(
              (data, index) =>
                data?.user?.level &&
                data?.user?.fullName && (
                  <div
                    key={data._id}
                    ref={(x) =>
                      messageRefs.current
                        ? (messageRefs.current[index] = x)
                        : null
                    }
                    message-item={JSON.stringify({
                      _id: data._id,
                      createdTime: data.createdTime,
                    })}
                  >
                    {data.type === MessageTypes.NOTIFICATION ? (
                      <div className={"notification"} ref={newMessageRef}>
                        {/* <small className="d-inline "></small> */}
                        <p className="m-0">
                          <span className="badgeA2zchat">Hi</span>

                          <span className={"fw-bold fullName"}>
                            {`${data.message.replace(textJoinRoom, "")}`}
                          </span>
                          <span className={"textJoinRoom"}>{textJoinRoom}</span>
                        </p>
                      </div>
                    ) : (
                      <div
                        className={"messageItem d-flex justify-content-between"}
                        ref={newMessageRef}
                      >
                        {data.type !== MessageTypes.MESSAGE_PIN && (
                          <div className="d-flex justify-content-start flex-column">
                            {data?.user?.level && data?.user?.fullName && (
                              <div>
                                <p className="m-0">
                                  <span className={"fw-bold fullName"}>
                                    <span
                                      className={`badgeA2zchat ${
                                        data?.user?.level === 1
                                          ? "lv1"
                                          : data?.user?.level === 2
                                          ? "lv2"
                                          : data?.user?.level === 3
                                          ? "lv3"
                                          : data?.user?.level === 4
                                          ? "lv4"
                                          : data?.user?.level === 5
                                          ? "lv5"
                                          : "lv1"
                                      }`}
                                    >
                                      -
                                    </span>
                                    {`${data?.user?.fullName
                                      ?.toString()
                                      .trim()}: `}
                                  </span>
                                  {!data.replyTo ? data.message : ""}
                                </p>
                              </div>
                            )}

                            {data.replyTo &&
                            data?.user?.level &&
                            data?.user?.fullName ? (
                              <>
                                <div
                                  className="border-start ps-2  cursor-pointer"
                                  onClick={() => {
                                    jumpToMessage(data.replyTo?._id);
                                  }}
                                >
                                  <div>
                                    <small className={"fullName"}>
                                      {data.replyTo?.user?.fullName
                                        ?.toString()
                                        .trim() + ": "}
                                    </small>
                                    <small className="text-muted">
                                      {data.replyTo?.message}
                                    </small>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {data.replyTo ? data.message : ""}

                            {/* <small className='text-muted'>{formatDate(data.createdTime)}</small> */}
                          </div>
                        )}

                        {data.type !== MessageTypes.MESSAGE_PIN && (
                          <div className={"containerIconItemChat"}>
                            <div
                              className={`h-100 d-flex justify-content-between iconReply`}
                            >
                              <div
                                className="align-self-center text-end cursor-pointer text-black-50 w-100"
                                onClick={() => {
                                  setDataToReply(data);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-reply-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                </svg>
                              </div>
                            </div>

                            {logedInUser?.user?.role?.roleName === "Admin" && (
                              <div
                                className={`align-self-center text-end cursor-pointer text-black-50 w-100 iconPin`}
                                onClick={() => {
                                  handlePinMessage(data);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pin"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282z" />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        ) : (
          <div>Không có dữ liệu</div>
        )}

        <EmbedChats
          roomId={roomId}
          socket={socket}
          logedInUser={logedInUser}
          setIsShowSignUp={setIsShowSignUp}
          setIsShowLogin={setIsShowLogin}
          setLogedInUser={setLogedInUser}
          dataToReply={dataToReply}
          setDataToReply={setDataToReply}
        />

        {isShowLogin ? (
          <div className="userContainer">
            <div className={`loginContainer`}>
              <span
                className={"closeForm"}
                onClick={() => {
                  setIsShowLogin(false);
                }}
              >
                x
              </span>

              <div className={"formLogo"}>
                <image
                  src="/embed/logo-1.png"
                  alt="logo_keovip"
                  width={150}
                  height={45}
                />
              </div>
              <form className="col-10 m-auto" onSubmit={login}>
                <div>
                  <label>
                    <small>Tên tài khoản:</small>
                  </label>
                  <input
                    name="userName"
                    type="text"
                    className={`form-control form-control-sm formInput`}
                    placeholder="Nhập tên tài khoản"
                  />
                </div>
                <div className="mt-2">
                  <label>
                    <small>Mật khẩu:</small>
                  </label>
                  <input
                    name="password"
                    type="password"
                    className={`form-control form-control-sm formInput`}
                    placeholder="Nhập mật khẩu"
                  />
                </div>
                <div className="text-center mt-3">
                  <button
                    className={`btn btn-secondary btn-sm btnCustomEmbed btnSignin`}
                    type="submit"
                  >
                    Đăng nhập
                  </button>
                </div>
                <div
                  className="cursor-pointer text-center mt-2"
                  onClick={() => {
                    setIsShowLogin(false);
                    setIsShowSignUp(true);
                  }}
                >
                  <small className={"textb"}>
                    Chưa có tài khoản?{" "}
                    <span className={"textSignup"}>Đăng ký</span>
                  </small>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        {isShowSignUp ? (
          <div className={`userContainer`}>
            <div className={"signUpContainer"}>
              <span
                className={"closeForm"}
                onClick={() => {
                  setIsShowSignUp(false);
                }}
              >
                x
              </span>
              <div className={"formLogo"}>
                <image
                  src="/embed/logo-1.png"
                  alt="logo_keovip"
                  width={150}
                  height={45}
                />
              </div>
              <form className="col-11 m-auto " onSubmit={signUp}>
                <div>
                  <label>
                    <small>Tên tài khoản:</small>
                  </label>
                  <input
                    name="userName"
                    type="text"
                    className={`form-control form-control-sm formInput`}
                    placeholder="Nhập tên tài khoản"
                  />
                </div>
                <div className="mt-2">
                  <label>
                    <small>Mật khẩu:</small>
                  </label>
                  <input
                    name="password"
                    type="password"
                    className={`form-control form-control-sm formInput`}
                    placeholder="Nhập mật khẩu"
                  />
                </div>

                <div className="mt-2">
                  <label>Tên hiển thị:</label>
                  <input
                    name="fullName"
                    type="text"
                    className={`form-control form-control-sm formInput`}
                    placeholder="Nhập tên hiển thị"
                  />
                </div>
                <div className="text-center mt-3">
                  <button
                    className={`btn btn-secondary btn-sm btnCustomEmbed btnSignup`}
                    type="submit"
                  >
                    Đăng ký
                  </button>
                </div>
                <div
                  className="cursor-pointer text-center mt-2"
                  onClick={() => {
                    setIsShowSignUp(false);
                    setIsShowLogin(true);
                  }}
                >
                  <small className={"textb"}>
                    Đã có tài khoản,{" "}
                    <span className={"textSignup"}>Đăng nhập</span>
                  </small>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        {showScrollToBottomButton ? (
          <div className={"scrollToBottomButtonArea"}>
            <div
              className={
                "p-1 text-secondary bg-light rounded-circle scrollToBottomButton"
              }
              onClick={scrollToNewMessage}
            >
              <image
                src="/embed/gif/scrollDown.gif"
                alt=""
                width={20}
                height={20}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    confettiWidth: 0,
    confittiHeight: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        confettiWidth: window.innerWidth,
        confittiHeight: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
