import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
    name: 'clickedRoom',
    initialState: {},
    reducers: {
        setClickedRoom: (state, action) => {
            state = action.payload
            return state
        }
    }
})

const { reducer, actions } = slice;
export const { setClickedRoom } = actions;
export default reducer;