const checkPermission = (permissionFieldName, permissionFunction) => {
  const user = JSON.parse(sessionStorage.getItem("infoUsers") || "null");

  if (user) {
    const permission = user?.user?.role?.permission || [];
    const checkRole = permission?.find((item) => {
      return (
        (item.permissionFieldName == permissionFieldName &&
          permissionFunction == permissionFunction) ||
        (item.permissionFieldName == "manage" &&
          item.permissionFunction == "all")
      );
    });

    if (checkRole) {
      return true;
    }

    return false;
  } else {
    return false;
  }
};

export default checkPermission;
