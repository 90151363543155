import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from "../../assets/styles/RoomInfo.module.css";
import { confirmAlert } from 'react-confirm-alert';
import { Chat } from '../../models/Chat';
import { Room } from '../../models/Room';
import { User } from '../../models/User';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import defaultAvatar from '../../assets/images/default-avatar.webp'
import { useNavigate } from 'react-router-dom';
import { MessageTypes } from '../../common/const';

export default function ManageMember({ room, user, onlineMembers, socket, setRoom }) {
    toastr.options.positionClass = 'toast-top-center';
    const navigate = useNavigate();
    
    const [isShowLoading, setIsShowLoading] = useState(false);
    const [members, setMembers] = useState(new Array());

    useEffect(() => {
        setMembers(room.members);
    }, [room])

    async function searchMember(event) {
        event.preventDefault();
        setIsShowLoading(true);

        let members = await axios.get(`/api/usersrooms/getAllByRoom/${room._id}?search=${event.target.search.value}`);
        setMembers(members);
        setTimeout(() => {
            setIsShowLoading(false);
        }, 300)
    }

    async function deleteMember(userToDelete) {
        confirmAlert({
            title: '',
            message: 'Bạn có chắc chắn muốn xóa?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setIsShowLoading(true);
                        try {
                            let response = await axios.delete(`/api/usersrooms/delete?room=${room._id}&user=${userToDelete._id}`);
                            console.log('deleteMember', response)
                            if (response.status === 1) {
                                setRoom((oldRoom) => {
                                    return { ...oldRoom, members: oldRoom.members.filter(x => x.user._id !== userToDelete._id) };
                                });
                                toastr.success('Xóa thành viên thành công!');
                                let dataToInsert = { message: user.fullName + ' đã xóa ' + userToDelete.fullName, room: room._id, type: MessageTypes.NOTIFICATION }

                                let insertChatResponse = await axios.post('/api/chat/insert', dataToInsert);
                                if (insertChatResponse.status === 1) {
                                    socket.emit('send_message', insertChatResponse.data);
                                }

                                setTimeout(() => {
                                    setIsShowLoading(false);
                                }, 300)
                            }
                            else if (response.status === 0) {
                                toastr.warning('Thành viên không tồn tại!');
                            }
                        } catch (error) {
                            if (error.response?.status === 403) {
                                toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                                navigate('/login')
                            }
                            else if (error.response?.status === 401) {
                                toastr.error('Bạn không có quyền thực hiện thao tác này!');
                            }
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    return (
        <>
            <div className='d-flex'>
                <div className='pt-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" style={{ transform: 'rotate(90deg)' }}>
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </div>
                <div className='w-100'>
                    <form onSubmit={searchMember}>
                        <input type="text" name='search' className='form-control border-0' placeholder='Tìm kiếm' />
                    </form>
                </div>
            </div>
            <div className={styles.listMembers} style={{height: '300px'}}>
                {
                    members?.filter(x => x.user.userName !== 'admin')?.map((member) => (
                        <div key={member.user._id} className={'d-flex p-2'}>
                            <div className='rounded-circle'>
                                <img className='rounded-circle' width={40} height={40} src={(member.user.avatar) ? ((process.env.UPLOAD_FOLDER_URL ?? 'http://localhost:5555/uploads') + member.user.avatar) : defaultAvatar} alt={member.user.fullName ?? 'user-avatar'} />
                            </div>
                            <div className='ms-2 w-100'>
                                <div>
                                    <small className='fw-bold allow-one-line'>
                                        {member.user.fullName}
                                    </small>
                                </div>
                                {
                                    (onlineMembers?.filter((x) => x.user?._id === member.user._id)?.length > 0) ? (
                                        <small className='text-primary d-block'>online</small>
                                    ) : (
                                        <small className='text-muted d-block'>offline</small>
                                    )
                                }
                            </div>
                            {
                                (member.user._id !== user._id) ? (
                                    <div className='ms-auto pt-2'>
                                        <small className='cursor-pointer text-danger' onClick={() => { deleteMember(member.user) }}>
                                            Xóa
                                        </small>
                                    </div>
                                ) : (
                                    <div className='pt-2 text-primary'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                        </svg>
                                    </div>
                                )
                            }

                        </div>
                    ))
                }
            </div>
        </>
    );
}
