import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { User } from "../../models/User";
import axios from "axios";
import { setClickedRoom } from "../../redux/rooms/clickedRoomSlice";
import { Friend } from "../../models/Friend";
import toastr from "toastr";
import "toastr/build/toastr.css";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../../assets/images/default-avatar.webp";

export default function UserInfo({ user, hideModal }) {
  toastr.options.positionClass = "toast-top-center";

  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState(new User());
  const [onlineUsers, setOnlineUsers] = useState(new Array());
  const [friends, setFriends] = useState(new Array());

  const storedUserInfo = useSelector((state) => state.userInfo);
  const storedOnlineUser = useSelector((state) => state.onlineUsers);

  const navigate = useNavigate();

  useEffect(() => {
    setUserInfo(storedUserInfo);
  }, [storedUserInfo]);

  useEffect(() => {
    if (user._id) {
      axios.get(`/api/friend/getAllByUser/${user._id}`).then((response) => {
        console.log("friend", response);
        setFriends(response);
      });
    }
  }, [user]);

  useEffect(() => {
    setOnlineUsers(storedOnlineUser);
  }, [storedOnlineUser]);

  async function sendPersonalMessage(user) {
    try {
      if (user) {
        let response = await axios.post(
          `/api/room/createPersonalRoom/${user._id}`
        );
        console.log("sendPersonalMessage", response);
        if (response.data.status === 1 || response.data.status === 2) {
          hideModal(false);
          const action = setClickedRoom(response.data.data);
          dispatch(action);
        }
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
        navigate("/login");
      } else if (error.response?.status === 401) {
        toastr.error("Bạn không có quyền thực hiện thao tác này!");
      }
    }
  }

  async function deleteFriend(friend) {
    if (friend._id) {
      try {
        let response = await axios.delete(`/api/friend/delete/${friend._id}`);
        if (response.status === 1) {
          toastr.success("Xóa bạn bè thành công!");
          setFriends((oldFriends) => {
            return oldFriends.filter((x) => x._id !== friend._id);
          });
        } else if (response.status === 0) {
          toastr.error("Người này đã được xóa!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function addFriend(user) {
    try {
      if (user._id) {
        let response = await axios.post("/api/friend/insert", {
          friend: user._id,
        });
        if (response.status === 1) {
          toastr.success("Thêm bạn thành công!");
          setFriends(response.data.friends);
        } else if (response.status === 0) {
          toastr.error("Đã có trong danh sách bạn bè!");
        }
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
        navigate("/login");
      } else if (error.response?.status === 401) {
        toastr.error("Bạn không có quyền thực hiện thao tác này!");
      }
    }
  }

  return (
    <>
      {user._id === userInfo._id ? (
        <>
          <div className="d-flex">
            <div className="rounded-circle">
              <img
                className="rounded-circle"
                width={60}
                height={60}
                src={
                  userInfo.avatar
                    ? process.env.UPLOAD_FOLDER_URL + userInfo.avatar
                    : process.env.DEFAULT_AVATAR_IMAGE_URL ??
                      "/uploads/default-avatar.jpg"
                }
                alt={userInfo.fullName ?? "user-avatar"}
              />
            </div>
            <div className="ms-2 w-100">
              <div className="w-100 d-flex justify-content-between">
                <h5 className="fw-bold m-0 pt-2">{userInfo.fullName}</h5>
                {userInfo.role === "owner" ? (
                  <small className="text-primary">{userInfo.role}</small>
                ) : (
                  <></>
                )}
              </div>
              {onlineUsers.filter((x) => x.user?._id === userInfo._id).length >
              0 ? (
                <small className="text-primary d-block">online</small>
              ) : (
                <small className="text-muted d-block">offline</small>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="border-bottom pb-3">
            <div className="d-flex ps-3 pe-3">
              <div className="rounded-circle">
                <img
                  className="rounded-circle"
                  width={60}
                  height={60}
                  src={
                    userInfo.avatar
                      ? (process.env.UPLOAD_FOLDER_URL ??
                          "http://localhost:5555/uploads") + userInfo.avatar
                      : defaultAvatar
                  }
                  alt={userInfo.fullName ?? "user-avatar"}
                />
              </div>
              <div className="ms-2 w-100">
                <div className="w-100 d-flex justify-content-between">
                  <h5 className="fw-bold m-0 pt-2">{userInfo.fullName}</h5>
                  {userInfo.role === "owner" ? (
                    <small className="text-primary">{userInfo.role}</small>
                  ) : (
                    <></>
                  )}
                </div>
                {onlineUsers.filter((x) => x.user?._id === userInfo._id)
                  .length > 0 ? (
                  <small className="text-primary d-block">online</small>
                ) : (
                  <small className="text-muted d-block">offline</small>
                )}
              </div>
            </div>
            <p className="mt-2 mb-0 ps-3 pe-3">{userInfo.bio}</p>
          </div>
          <div className="d-flex justify-content-between p-3 mt-2">
            <div
              className="text-white bg-success rounded ps-3 pe-3 pt-1 pb-1 cursor-pointer"
              onClick={() => sendPersonalMessage(userInfo)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
              </svg>
              <span className="ms-2">Gửi tin nhắn</span>
            </div>
            {friends?.filter((x) => x.friend._id === userInfo._id)?.length > 0 ? (
              <div
                className="text-danger rounded ps-2 pe-2 pt-1 pb-1 cursor-pointer"
                onClick={() => {
                  deleteFriend(
                    friends.filter((x) => x.friend._id === userInfo._id)[0]
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
                <span className="ms-2">Xóa bạn bè</span>
              </div>
            ) : (
              <div
                className="text-white bg-primary rounded ps-2 pe-2 pt-1 pb-1 cursor-pointer"
                onClick={() => {
                  addFriend(userInfo);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>
                <span className="ms-1">Thêm bạn bè</span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
