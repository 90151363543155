import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("infoUsers");

  if (user) return JSON.parse(user)?.user;
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};
//login
export const login = (data) => api.post(url.API_USER_LOGIN, data);

//User
export const insertUser = (data) => api.post(url.API_USER_INSERT, data);
export const updateUser = (id, data) =>
  api.put(`${url.API_USER_UPDATE}/${id}`, data);
export const deleteUser = (id, data) =>
  api.delete(`${url.API_USER_DELETE}/${id}`, data);
export const getPagingUser = (data) =>
  api.get(`${url.API_USER_GET_PAGING}?${data}`);
export const getUserById = (id, data) =>
  api.get(`${url.API_USER_GET_PAGING_BY_ID}/${id}`, data);

//Role
export const insertRole = (data) => api.post(url.API_ROLE_INSERT, data);
export const updateRole = (id, data) =>
  api.put(`${url.API_ROLE_UPDATE}/${id}`, data);
export const deleteRole = (id, data) =>
  api.delete(`${url.API_ROLE_DELETE}/${id}`, data);
export const getAllRole = (data) => api.get(url.API_ROLE_GETALL, data);
export const getPagingRole = (data) => api.get(url.API_ROLE_GET_PAGING, data);
export const getRoleById = (id, data) =>
  api.get(`${url.API_ROLE_GET_PAGING_BY_ID}/${id}`, data);
export const createUser = (data) => {
  return api.post(`${url.API_USER_INSERT}`, data);
};

export const updatePermission = (id, data) => {
  return api.put(`${url.API_ROLE_UPDATE_PERMISSION}/${id}`, data);
};

export const getPagingRoom = ({
  pageSize = 10,
  pageIndex = 1,
  search = "",
}) => {
  return api.get(
    `${url.API_GET_ROOM}?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${search}`
  );
};

export const getRoomById = (id) => {
  return api.get(`${url.API_GET_ROOM_BY_ID}/${id}`);
};
export const createRoom = (data) => {
  return api.post(`${url.API_CREATE_ROOM}`, data);
};

export const updateRoom = (id, data) => {
  return api.put(`${url.API_UPDATE_ROOM}/${id}`, data);
};

export const deleteRoom = (id) => {
  return api.delete(`${url.API_ROOM_DELETE}/${id}`);
};

export const banUser = (id, userId) => {
  return api.put(`${url.API_BAN_USER}/${id}`, { userId });
};

export const deleteUserFromRoom = (user, room) => {
  return api.delete(`${url.API_DELETE_USER}?user=${user}&room=${room}`);
};
export const getPagingLogRoom = (pageIndex, pageSize, search, room) => {
  return api.get(
    `${url.API_GET_LOG_ROOM}?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${search}&room=${room}`
  );
};
