import React, { useEffect, useState } from "react";
import { getPagingLogRoom, getPagingRoom } from "../../helpers/helper";
import { Button, Input, Pagination, Select, Table } from "antd";
import { BsCheckLg, BsSearch } from "react-icons/bs";

function LogRoomManagement() {
  const [logs, setLogs] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState();

  const getLogsRoom = async (pageIndex, pageSize, search, room) => {
    try {
      const result = await getPagingLogRoom(pageIndex, pageSize, search, room);
      setLogs(result.data || []);
      setPageSize(Number(result.pageSize));
      setPageIndex(Number(result.pageIndex));
      setCount(result.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getRooms = async () => {
    try {
      const result = await getPagingRoom({
        pageSize: 9999,
        pageIndex,
        search: "",
      });
      setRooms(
        (result.data || [])?.map((item) => ({
          value: item?._id,
          label: item?.roomName,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLogsRoom(1, 10, "", "");
    getRooms();
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (_, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      render: (_, record, index) => {
        return <>{_}</>;
      },
    },
    {
      title: "Phòng chat",
      dataIndex: "_id",
      key: "_id",
      render: (_, record, index) => {
        return <>{record?.room?.roomName}</>;
      },
    },
    {
      title: "Người dùng",
      dataIndex: "_id",
      key: "_id",
      render: (_, record, index) => {
        return <>{record?.user?.userName}</>;
      },
    },
    {
      title: "Người dùng thực hiện",
      dataIndex: "_id",
      key: "_id",
      render: (_, record, index) => {
        return <>{record?.user?.userName}</>;
      },
    },
  ];

  return (
    <div>
      <div className="bold">Quản lý log phòng chat</div>
      <div style={{ marginTop: "15px" }}>
        <Input
          style={{ maxWidth: "300px" }}
          placeholder="Nhập tìm kiếm..."
          value={search}
          onChange={(e) => setSearch(e?.target?.value || "")}
        />
        <Select
          placeholder="Chọn phòng chat"
          style={{ width: "200px" }}
          className="ms-3"
          options={rooms}
          allowClear
          onChange={(e) => setRoom(e)}
          value={room}
        />
        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            getLogsRoom(1, pageSize, search, room || "");
          }}
        >
          <BsSearch />
        </Button>
      </div>

      <div>
        <Table
          style={{ marginTop: "15px" }}
          dataSource={logs}
          columns={columns}
          pagination={false}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Pagination
          style={{ marginTop: "15px" }}
          current={pageIndex}
          total={count}
          onChange={(page, pageSize) => {
            getLogsRoom(page, pageSize, search, room || "");
          }}
        />
      </div>
    </div>
  );
}

export default LogRoomManagement;
