import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLoggedInUser, getRoomById } from "../../helpers/helper";
import styles from "../../assets/styles/Room.module.css";
import RoomName from "../../components/room/RoomName";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  Offcanvas,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { MessageTypes, Roles, RoomRoles, RoomTypes } from "../../common/const";
import io from "socket.io-client";
import Messages from "../../components/message/Messages";
import Chats from "../../components/Chats";
import RoomInfo from "../../components/room/RoomInfo";
import { useDispatch } from "react-redux";
import { setOnlineUsers } from "../../redux/users/onlineUsersSlice";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import toastr from "toastr";
import "toastr/build/toastr.css";
import { Toaster } from "react-hot-toast";
import UserInfo from "../../components/Users/UserInfo";
import MessageSearching from "../../components/message/MessageSearching";
import EditRoom from "../../components/room/EditRoom";
import QRCode from "react-qr-code";

const socket = io(
  process.env.WEBSOCKET_SERVER ?? "https://socket.truonggasavan.online",
  {
    autoConnect: false,
  }
);

function RoomDetailManagement() {
  const { pathname } = useLocation();
  const id = pathname?.split("/")?.[2];
  const [room, setRoom] = useState({});
  const navigate = useNavigate();
  const user = getLoggedInUser();
  const dispatch = useDispatch();

  const propertiesTabRef = useRef(null);
  const rightSideRef = useRef(null);

  const [dataToReply, setDataToReply] = useState({
    user: { fullName: "" },
    message: "",
  });
  const [dataToEdit, setDataToEdit] = useState({ message: "" });
  const [isShowReplyBox, setIsShowReplyBox] = useState(false);
  const [isShowEditBox, setIsShowEditBox] = useState(false);

  const [isShowLeftActionsTab, setIsShowLeftActionsTab] = useState(false);
  const [isShowMessageSearchingTab, setIsShowMessageSearchingTab] =
    useState(false);
  const [isShowCreateRoomModal, setIsShowCreateRoomModal] = useState(false);
  const [isShowEditRoomModal, setIsShowEditRoomModal] = useState(false);
  const [isShowFriendModal, setIsShowFriendModal] = useState(false);
  const [isShowAddNewFriendModal, setIsShowAddNewFriendModal] = useState(false);
  const [isShowUserInfoModal, setIsShowUserInfoModal] = useState(false);
  const [isShowLogedUserInfoModal, setIsShowLogedUserInfoModal] =
    useState(false);
  const [isShowShareRoomModal, setIsShowShareRoomModal] = useState(false);
  const [isShowImportExcelModal, setIsShowImportExcelModal] = useState(false);
  const [isShowImportMessageModal, setIsShowImportMessageModal] =
    useState(false);

  const getRoom = async () => {
    try {
      const result = await getRoomById(id);
      if (result) {
        setRoom(result || {});
      } else {
        navigate("/manage-room");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoom();
  }, []);

  useEffect(() => {
    if (room?._id) {
      socket.emit("join_room", {
        user: user,
        room,
      });

      socket.on("connected_users", (data) => {
        const action = setOnlineUsers(data);
        dispatch(action);
      });
    }
  }, [room, socket]);

  function clickReplyButton(data) {
    setDataToReply(data);
    setIsShowReplyBox(true);
  }

  function clickEditButton(data) {
    setDataToEdit(data);
    setIsShowEditBox(true);
  }

  function triggerRoomInfo() {
    propertiesTabRef.current?.classList.toggle(styles.hidePropertiesTab);
    rightSideRef.current?.classList.toggle(styles.expandRightSide);
  }

  async function outRoom() {
    if (room._id) {
      confirmAlert({
        title: "",
        message: "Bạn có chắc chắn muốn thoát nhóm?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                let response = await axios.delete(
                  `/api/usersrooms/outRoom?room=${room._id}`
                );

                if (response.status === 1) {
                  let dataToInsert = {
                    message: user.fullName + " đã thoát nhóm",
                    room: room._id,
                    type: MessageTypes.NOTIFICATION,
                  };

                  let insertChatResponse = await axios.post(
                    "/api/chat/insert",
                    dataToInsert
                  );

                  if (insertChatResponse.status === 1) {
                    socket.emit("send_message", insertChatResponse.data);
                  }
                  socket.emit("out_room", response.data);
                }
              } catch (error) {
                if (error.response?.status === 403) {
                  toastr.error(
                    "Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!"
                  );
                  navigate("/manage-room");
                } else if (error.response?.status === 401) {
                  toastr.error("Bạn không có quyền thực hiện thao tác này!");
                }
              }
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  }

  async function deleteRoom() {
    if (room._id) {
      confirmAlert({
        title: "",
        message: "Bạn có chắc chắn muốn xóa?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                let response = await axios.delete(
                  `/api/room/delete/${room._id}`
                );
                if (response.status === 1) {
                  socket.emit("delete_room", room);
                }
              } catch (error) {
                if (error.response?.status === 401) {
                  toastr.error("Bạn không có quyền xóa nhóm này!");
                } else if (error.response?.status === 403) {
                  toastr.error(
                    "Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!"
                  );
                  navigate("/login");
                }
              }
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  }

  function copyRoomLink() {
    navigator.clipboard.writeText(process.env.DOMAIN + "/" + room._id);
    toastr.success("Sao chép link nhóm thành công!");
  }

  function copyEmbedCode() {
    navigator.clipboard.writeText(
      `<iframe width="100%" height="100%" src="${
        process.env.DOMAIN + "/embed/" + room._id
      }" frameborder="0"></iframe>`
    );
    toastr.success("Sao chép mã nhúng thành công!");
  }

  function downloadQRCode() {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = document.createElement("img");
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = room._id ?? "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }

  return (
    <>
      <Toaster />

      <div className="d-flex">
        <div className={styles.rightSide} ref={rightSideRef}>
          <div
            className={
              "border-bottom pt-2 pb-2 ps-3 pe-3 sticky-top bg-light d-flex justify-content-between " +
              styles.topRightSide
            }
          >
            <RoomName room={room} user={user} />
            <div className="pt-2 d-flex text-secondary">
              <div
                className="me-4 cursor-pointer"
                onClick={() => {
                  setIsShowMessageSearchingTab(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
              <div className="me-4 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="currentColor"
                  className="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  />
                </svg>
              </div>
              <div className="me-4 cursor-pointer" onClick={triggerRoomInfo}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="currentColor"
                  className="bi bi-layout-sidebar-inset-reverse"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12z" />
                  <path d="M13 4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V4z" />
                </svg>
              </div>
              <OverlayTrigger
                trigger={"click"}
                placement="bottom"
                rootClose
                overlay={
                  <Popover>
                    <Popover.Body>
                      {room.roomType === RoomTypes.PUBLIC ||
                      room.roomType === RoomTypes.EMBED ? (
                        <div
                          className="cursor-pointer text-primary mb-2"
                          onClick={() => {
                            document.body.click();
                            setIsShowShareRoomModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-share"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                          </svg>
                          <span className="ms-2">Chia sẻ</span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="cursor-pointer text-secondary mb-2"
                        onClick={() => {
                          document.body.click();
                          outRoom();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                          />
                        </svg>
                        <span className="ms-2">Thoát nhóm</span>
                      </div>
                      {room?.members?.filter(
                        (x) => x?.user?._id === user?._id
                      )?.[0]?.role === RoomRoles.OWNER ||
                      room?.members?.filter(
                        (x) => x?.user?._id === user?._id
                      )?.[0]?.role === RoomRoles.ADMIN ||
                      user?.userName === "admin" ||
                      user?.role?.roleName === Roles.ADMIN ? (
                        <>
                          {room.roomType !== RoomTypes.PERSONAL ? (
                            <div
                              className="cursor-pointer text-primary mb-2"
                              onClick={() => {
                                document.body.click();
                                setIsShowEditRoomModal(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                              <span className="ms-2">Chỉnh sửa nhóm</span>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div
                            className="text-danger cursor-pointer"
                            onClick={() => {
                              document.body.click();
                              deleteRoom();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              className="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                            <span className="ms-2">Xóa nhóm</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-three-dots-vertical"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <Messages
            room={room}
            user={user}
            socket={socket}
            clickReplyButton={clickReplyButton}
            clickEditButton={clickEditButton}
            showUserInfoModal={setIsShowUserInfoModal}
            setRoom={setRoom}
          />

          {
            //Reply box
            isShowReplyBox === true ? (
              <div className={styles.displayReplyBox}>
                <div className="text-primary ps-2 pe-3 pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-reply-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                  </svg>
                </div>
                <div>
                  <small className="w-75 allow-one-line fw-bold">
                    {dataToReply.user.fullName}
                  </small>
                  <small className="d-block allow-one-line">
                    {dataToReply.message}
                  </small>
                </div>
                <div className="ms-auto pt-2">
                  <input
                    type="button"
                    className="align-content-center btn-close cursor-pointer"
                    onClick={() => {
                      setDataToReply({ user: { fullName: "" }, message: "" });
                      setIsShowReplyBox(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )
          }

          {
            //Edit box
            isShowEditBox === true ? (
              <div className={styles.displayEditBox}>
                <div className="text-primary ps-2 pe-3 pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-pencil-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                  </svg>
                </div>
                <div>
                  <small className="fw-bold allow-one-line">
                    Chỉnh sửa tin nhắn
                  </small>
                  <small className="w-75 allow-one-line">
                    {dataToEdit.message}
                  </small>
                </div>
                <div className="ms-auto pt-2">
                  <input
                    type="button"
                    className="align-content-center btn-close cursor-pointer"
                    onClick={() => {
                      setDataToEdit({ message: "" });
                      setIsShowEditBox(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )
          }

          <Chats
            room={room}
            user={user}
            socket={socket}
            dataToReply={dataToReply}
            dataToEdit={dataToEdit}
            setIsShowReplyBox={setIsShowReplyBox}
            setDataToReply={setDataToReply}
            setIsShowEditBox={setIsShowEditBox}
            setDataToEdit={setDataToEdit}
          />
        </div>
        <div className={styles.propertiesTab} ref={propertiesTabRef}>
          <RoomInfo
            user={user}
            room={room}
            socket={socket}
            setRoom={setRoom}
            showUserInfoModal={setIsShowUserInfoModal}
          />
        </div>
      </div>

      <Modal
        show={isShowUserInfoModal}
        onHide={() => {
          setIsShowUserInfoModal(false);
        }}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Body>
          <UserInfo user={user} hideModal={setIsShowUserInfoModal} />
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={isShowMessageSearchingTab}
        onHide={() => {
          setIsShowMessageSearchingTab(false);
        }}
        scroll={false}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h4>Tìm kiếm tin nhắn</h4>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="ps-1">
          <MessageSearching room={room} />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={isShowEditRoomModal}
        onHide={() => {
          setIsShowEditRoomModal(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Sửa thông tin nhóm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRoom
            socket={socket}
            triggerModal={setIsShowEditRoomModal}
            room={room}
            setRoom={setRoom}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={isShowShareRoomModal}
        onHide={() => {
          setIsShowShareRoomModal(false);
        }}
        backdrop={true}
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <div className="d-flex">
            <div className="col-3 pt-2 text-center">
              <QRCode
                id="QRCode"
                size={160}
                value={"https://admin.truongsavan.online" + "/" + room._id}
              />
              <input
                type="button"
                className="mt-2"
                value="Tải QR code"
                onClick={downloadQRCode}
              />
            </div>
            <div className="col-9">
              <h5 className="fw-bold">Link nhóm:</h5>
              <div className="d-flex">
                <h6 className="bg-light p-2 m-0 w-75 text-break">
                  {"https://admin.truonggasavan.online" + "/" + room._id}
                </h6>
                <input
                  className="btn btn-primary ms-2"
                  type="button"
                  value="Sao chép"
                  onClick={copyRoomLink}
                />
              </div>
              {room.roomType === RoomTypes.EMBED ? (
                <div>
                  <h5 className="fw-bold mt-3">Mã nhúng:</h5>
                  <div className="d-flex">
                    <h6 className="bg-light p-2 m-0 w-75 text-break">
                      {`<iframe width="100%" height="100%" src="${
                        "https://admin.truonggasavan.online" +
                        "/embed/" +
                        room._id
                      }" frameborder="0"></iframe>`}
                    </h6>
                    <input
                      className="btn btn-primary ms-2"
                      type="button"
                      value="Sao chép"
                      onClick={copyEmbedCode}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <h5 className="fw-bold mt-3">Mã nhúng:</h5>
                  <p className="m-0">Nhóm này không có mã nhúng!</p>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RoomDetailManagement;
