import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Room } from "../../models/Room";
import styles from "../../assets/styles/MessageSearching.module.css";
import { setSearchingMessage } from "../../redux/messages/searchingMessageSlice";
import defaultAvatar from "../../assets/images/default-avatar.webp";
import { formatDate } from "../../common/function";

export default function MessageSearching({ room }) {
  const dispatch = useDispatch();
  const [foundMessages, setFoundMessages] = useState(new Array());

  async function searchMessage(event) {
    event.preventDefault();
    let searchingText = event.target.searchingText.value;
    if (searchingText) {
      let messages = await axios.get(
        `/api/chat/searchMessage?room=${room._id}&searchingText=${searchingText}`
      );
      setFoundMessages(messages);
    }
  }

  function jumpToMessage(data) {
    const action = setSearchingMessage(data);
    dispatch(action);
  }

  return (
    <>
      <form className="d-flex ps-3" onSubmit={searchMessage}>
        <input
          type="text"
          name="searchingText"
          className="form-control form-control-sm me-1"
          placeholder="Nhập tin nhắn để tìm kiếm"
          autoFocus
          required
        />
        <input
          className="btn btn-primary btn-sm"
          type="submit"
          value="Tìm kiếm"
        />
      </form>
      <div className="pt-2">
        {foundMessages?.length > 0 ? (
          foundMessages?.map((message) => (
            <div
              className={"d-flex " + styles.item}
              key={message._id}
              onClick={() => jumpToMessage(message)}
            >
              <div className="rounded-circle">
                <img
                  className="rounded-circle"
                  width={50}
                  height={50}
                  src={
                    message.user?.avatar
                      ? process.env.UPLOAD_FOLDER_URL + message.user?.avatar
                      : defaultAvatar
                  }
                  alt={message.user?.fullName}
                />
              </div>
              <div className="w-100 ps-2">
                <div className="d-flex justify-content-between">
                  <div className="w-50 allow-one-line">
                    {message.user?.fullName}
                  </div>
                  <div className="w-50 allow-one-line text-end">
                    <small className="text-muted">
                      {formatDate(message.createdTime)}
                    </small>
                  </div>
                </div>
                <div className="allow-one-line">{message?.message}</div>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
