import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Room } from "../../models/Room";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.css";
import defaultAvatar from "../../assets/images/default-avatar.webp";
import defaultGroup from "../../assets/images/default-group.webp";
import { useNavigate } from "react-router-dom";

export default function EditRoom({ socket, triggerModal, room, setRoom }) {
  toastr.options.positionClass = "toast-top-center";
  const navigate = useNavigate();

  const [isShowLoading, setIsShowLoading] = useState(false);
  const roomIconRef = useRef(null);

  async function handleEditRoom(event) {
    event.preventDefault();
    let roomName = event.target.roomName.value;
    if (roomName) {
      try {
        let response = await axios.put(`/api/room/update/${room._id}`, {
          roomName: roomName,
          roomIcon: room.roomIcon,
          roomType: room.roomType,
        });
        if (response.status === 1) {
          socket.emit("edit_room", { ...room, ...response.data });
          triggerModal(false);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
          navigate("/login");
        } else if (error.response?.status === 401) {
          toastr.error("Bạn không có quyền thực hiện thao tác này!");
        }
      }
    }
  }

  async function onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();

    setIsShowLoading(true);
    var file = event.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      try {
        const response = await axios.post("/api/upload/uploadImage", formData);
        if (response.data.status === 1) {
          setRoom((oldRoom) => {
            return { ...oldRoom, roomIcon: response.data.data };
          });
          toastr.success("Upload ảnh thành công!");
          setTimeout(() => {
            setIsShowLoading(false);
          }, 300);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
          navigate("/login");
        } else if (error.response?.status === 401) {
          toastr.error("Bạn không có quyền thực hiện thao tác này!");
        }
      }
    }
  }

  function handleClickUploadRoomIcon() {
    roomIconRef?.current?.click();
  }

  function roomTypeChange(event) {
    setRoom((oldRoom) => {
      return { ...oldRoom, roomType: event.target.value };
    });
  }

  return (
    <form onSubmit={handleEditRoom} className="mt-2">
      <div className="d-flex">
        <div className="col-3 pt-4">
          <div
            className="h-100 rounded border cursor-pointer position-relative"
            onClick={handleClickUploadRoomIcon}
          >
            <img
              fill
              style={{ width: "100%", height: "100%" }}
              src={
                room.roomIcon
                  ? (process.env.UPLOAD_FOLDER_URL ??
                      "http://localhost:5555/uploads") + room.roomIcon
                  : defaultGroup
              }
              alt="upload-image-icon"
              priority
            />
            <input
              type="file"
              style={{ display: "none" }}
              ref={roomIconRef}
              onChange={onChangeFile}
              accept="image/*"
            />
          </div>
        </div>
        <div className="w-100 ps-3">
          <div>
            <label className="fw-bold">Kiểu nhóm:</label>
            <select
              name="roomType"
              className="form-select"
              value={room.roomType}
              onChange={roomTypeChange}
            >
              <option value="public">Công khai</option>
              <option value="private">Nhóm kín</option>
            </select>
          </div>
          <div className="mt-3">
            <label className="fw-bold">
              <span className="text-danger">(*)</span>Tên nhóm:
            </label>
            <input
              type="text"
              name="roomName"
              className="form-control"
              placeholder="Nhập tên nhóm"
              defaultValue={room.roomName}
              required
            />
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <input type="submit" value="Sửa nhóm" className="btn btn-primary" />
      </div>
    </form>
  );
}
