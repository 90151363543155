import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
    name: 'onlineUsers',
    initialState: [],
    reducers: {
        setOnlineUsers: (state, action) => {
            state = action.payload
            return state
        }
    }
})

const { reducer, actions } = slice;
export const { setOnlineUsers } = actions;
export default reducer;