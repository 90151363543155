import axios from "axios";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Chat } from "../../models/Chat";
import { Room } from "../../models/Room";
import toastr from "toastr";
import { MessageTypes } from ".";

export default function EmbedChats({
  roomId,
  socket,
  logedInUser,
  setIsShowSignUp,
  setIsShowLogin,
  setLogedInUser,
  dataToReply,
  setDataToReply,
}) {
  toastr.options.positionClass = "toast-top-center";
  toastr.options.escapeHtml = true;

  const txtMessageRef = useRef(null);

  async function sendMessage(message) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + logedInUser.token,
    };

    try {
      if (logedInUser) {
        let dataToInsert = {};
        if (dataToReply) {
          dataToInsert = {
            message: message,
            room: roomId,
            type: MessageTypes.MESSAGE,
            replyTo: dataToReply._id,
          };
        } else {
          dataToInsert = {
            message: message,
            room: roomId,
            type: MessageTypes.MESSAGE,
          };
        }

        let response = await axios.post(`/api/chat/insert`, dataToInsert, {
          headers: headers,
        });
        if (response.status === 1) {
          socket.emit("send_message", response.data);
        }
        setDataToReply(null);
      } else {
        setIsShowSignUp(true);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        setIsShowSignUp(true);
      } else if (error.response?.status === 401) {
        toastr.error("Bạn không có quyền thực hiện thao tác này!");
      }
    }
  }

  async function handleSendMessage(event) {
    event.preventDefault();
    if (event.target.message.value) {
      sendMessage(event.target.message.value);
      event.target.message.value = "";
    }
  }

  async function handleSendMessageOnEnter(event) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      if (event.target.value) {
        sendMessage(event.target.value);
        event.target.value = "";
      }
    }
  }

  function logOut() {
    localStorage.removeItem("logedInUser");
    setLogedInUser(null);
    // setIsShowSignUp(true);
    setIsShowLogin(true);
  }

  function selectEmoji(emojiData, event) {
    if (txtMessageRef.current) {
      let selectionStart = txtMessageRef.current.selectionStart;
      let selectionEnd = txtMessageRef.current.selectionEnd;
      let emoji = emojiData.emoji;

      txtMessageRef.current.value =
        txtMessageRef.current.value.substring(0, selectionStart) +
        emoji +
        txtMessageRef.current.value.substring(selectionEnd);

      txtMessageRef.current.setSelectionRange(
        selectionStart + 2,
        selectionStart + 2
      );
      txtMessageRef.current.focus();
    }
  }

  useEffect(() => {
    if (dataToReply) {
      txtMessageRef.current?.focus();
    }
  }, [dataToReply]);

  function handleCheckTimeClick(name) {
    let timeAnimationGift = localStorage.getItem(name);
    if (timeAnimationGift) {
      let date = new Date(timeAnimationGift);
      let checkTime = (new Date().getTime() - date.getTime()) / 1000;
      if (checkTime > 15) {
        localStorage.setItem(name, new Date().toString());
        return true;
      } else {
        let stillWaitInSeconds = 15 - parseInt(checkTime.toString());
        toastr.remove();
        toastr.warning(`Hãy đợi ${stillWaitInSeconds} giây nữa!`);
        return false;
      }
    } else {
      localStorage.setItem(name, new Date().toString());
      return true;
    }
  }

  function handleClickGift(level) {
    if (!logedInUser) {
      toastr.warning(`Vui lòng đăng nhập để sử dụng!`);
    } else if (level <= Number(logedInUser.user.level)) {
      switch (level) {
        case 1:
          const clicked1 = handleCheckTimeClick("animationGiftLV1");
          if (clicked1) {
            const data1 = new Chat(
              logedInUser,
              "animationGiftLV1",
              new Room(roomId),
              MessageTypes.ANIMATION_GIFT,
              new Date()
            );
            socket.emit("send_message", data1);
          }

          break;
        case 2:
          const clicked2 = handleCheckTimeClick("animationGiftLV2");
          if (clicked2) {
            const data2 = new Chat(
              logedInUser,
              "animationGiftLV2",
              new Room(roomId),
              MessageTypes.ANIMATION_GIFT,
              new Date()
            );
            socket.emit("send_message", data2);
          }
          break;
        case 3:
          const clicked3 = handleCheckTimeClick("animationGiftLV3");
          if (clicked3) {
            const data3 = new Chat(
              logedInUser,
              "animationGiftLV3",
              new Room(roomId),
              MessageTypes.ANIMATION_GIFT,
              new Date()
            );
            socket.emit("send_message", data3);
          }

          break;
        case 4:
          const clicked4 = handleCheckTimeClick("animationGiftLV4");
          if (clicked4) {
            const data4 = new Chat(
              logedInUser,
              "animationGiftLV4",
              new Room(roomId),
              MessageTypes.ANIMATION_GIFT,
              new Date()
            );
            socket.emit("send_message", data4);
          }

          break;
        case 5:
          const clicked5 = handleCheckTimeClick("animationGiftLV5");
          if (clicked5) {
            const data5 = new Chat(
              logedInUser,
              "animationGiftLV5",
              new Room(roomId),
              MessageTypes.ANIMATION_GIFT,
              new Date()
            );
            socket.emit("send_message", data5);
          }

          break;
        default:
          break;
      }
    } else {
      toastr.warning(`Bạn không đủ cấp để sử dụng!`);
    }
  }

  return (
    <div className="chatBox">
      {dataToReply ? (
        <div className="d-flex justify-content-between align-items-center pt-2 pb-2 border-bottom">
          <div className="ms-2 text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              className="bi bi-reply-fill"
              viewBox="0 0 16 16"
            >
              <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"></path>
            </svg>
          </div>
          <div className="w-100 ms-2">
            <div className="lh-1">
              <small className="fw-bold">{dataToReply?.user.fullName}</small>
            </div>
            <div className="lh-1 allow-one-line">
              <small>{dataToReply?.message}</small>
            </div>
          </div>
          <div className="ms-2 me-2">
            <button
              className="btn-close"
              style={{ fontSize: "12px" }}
              onClick={() => {
                setDataToReply(null);
              }}
            ></button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="d-flex justify-content-between pb-2 ps-2 pe-2">
        {logedInUser?.user?._id ? (
          <div className="d-flex col-7 justify-content-end cursor-pointer">
            <div className="text-dark w-50 text-end allow-one-line">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
              </svg>
              <small className="ms-1">{logedInUser.user.fullName}</small>
            </div>
            <div className="ms-2 cursor-pointer" onClick={logOut}>
              <small className="text-dark" style={{ fontSize: "12px" }}>
                (Đăng xuất)
              </small>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="d-flex">
        <OverlayTrigger
          trigger={"click"}
          placement="top"
          offset={[0, 25]}
          overlay={
            <Popover>
              <Popover.Body className="p-0">
                <EmojiPicker
                  width={250}
                  height={300}
                  onEmojiClick={selectEmoji}
                  autoFocusSearch={false}
                  theme={Theme.LIGHT}
                  emojiStyle={EmojiStyle.TWITTER}
                  lazyLoadEmojis={true}
                  skinTonesDisabled={true}
                  searchDisabled={true}
                  previewConfig={{
                    showPreview: false,
                  }}
                />
              </Popover.Body>
            </Popover>
          }
        >
          <div className={"text-dark text-center cursor-pointer emoji"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="30"
              fill="currentColor"
              className="bi bi-emoji-smile"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
            </svg>
          </div>
        </OverlayTrigger>

        <form
          className="w-100 d-flex"
          onSubmit={handleSendMessage}
          onKeyDownCapture={handleSendMessageOnEnter}
        >
          <div className={"txtMessage"}>
            {logedInUser ? (
              <input
                ref={txtMessageRef}
                name="message"
                type="text"
                className="form-control form-control-sm"
                placeholder="Nhập tin nhắn"
                autoComplete="off"
                style={{ fontSize: "16px" }}
              />
            ) : (
              <div
                className="w-100 text-center position-absolute bottom-0 pb-1 cursor-pointer"
                onClick={() => {
                  setIsShowSignUp(true);
                }}
              >
                Tham gia chat
              </div>
            )}
          </div>
          <div className={"text-center sendButton"}>
            <button
              type="submit"
              className={"border-0 bg-transparent p-0 text-dark"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="30"
                fill="currentColor"
                className={"bi bi-send-fill sendIcon"}
                viewBox="0 0 16 16"
              >
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
