import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
    name: 'gifs',
    initialState: [],
    reducers: {
        setGifs: (state, action) => {
            if (action.payload) {
                state = action.payload;
                return state;
            }
        },
        addGif: (state, action) => {
            if (action.payload) {
                action.payload.forEach((item) => {
                    state.push(item);
                });
            }
        },
        deleteGif: (state, action) => {
            if (action.payload) {
                return state.filter((x) => x._id !== action.payload._id);
            }
        }
    }
})

const { reducer, actions } = slice;
export const { setGifs, deleteGif } = actions;
export default reducer;