import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import Login from "../pages/auth/login";
import Logout from "../pages/auth/logout";
import Page404 from "../pages/error/404";

import AuthProtectedLayout from "../layouts/";
import EmbedRoom from "../pages/embed";
import { Button, Result } from "antd";
import checkPermission from "../helpers/checkPermission";
import { permissionFieldName } from "../helpers/permissionFieldName";
import { permissionFunction } from "../helpers/permissionFunction";

const Index = () => {
  const AuthProtected = ({ children }) => {
    let auth = sessionStorage.getItem("token") ? true : false;
    if (!auth) {
      return <Navigate to="/sign-in" />;
    }
    return children;
  };

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path="/*"
            element={
              checkPermission(
                permissionFieldName.loginAdmin.key,
                permissionFunction.READ
              ) ? (
                <AuthProtected>
                  <AuthProtectedLayout />
                </AuthProtected>
              ) : (
                <Result
                  status={"403"}
                  title="Không có quyền truy cập trang này"
                  extra={
                    <Link to={"/logout"}>
                      <Button type="primary">Đăng xuất</Button>
                    </Link>
                  }
                />
              )
            }
          />
          <Route element={<EmbedRoom />} path="/embed/:roomId" />
          <Route element={<Login />} path="/sign-in" />
          <Route element={<Logout />} path="/logout" />
          <Route element={<Page404 />} path="/404" />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default Index;
