import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
    name: 'searchingMessage',
    initialState: null,
    reducers: {
        setSearchingMessage: (state, action) => {
            state = action.payload;
            return state;
        }
    }
})

const { reducer, actions } = slice;
export const { setSearchingMessage } = actions;
export default reducer;