import { useEffect, useState } from "react";
import {
  getAllRole,
  getRoleById,
  updatePermission,
} from "../../helpers/helper";
import { Button, Checkbox, Select, Table } from "antd";
import { permissionFieldName } from "../../helpers/permissionFieldName";
import { permissionFunction } from "../../helpers/permissionFunction";

const ManagePermission = () => {
  const [selectedRole, setSelectedRole] = useState();
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState([]);
  const [roles, setRoles] = useState([]);
  const getRoles = async () => {
    try {
      const result = await getAllRole();
      setRoles(result || []);
    } catch (error) {
      console.log(error);
    }
  };
  const rolesGroup = roles.map((item) => {
    return {
      label: item.roleName,
      value: item._id,
    };
  });

  const getRole = async () => {
    try {
      const thisRole = await getRoleById(selectedRole);
      setPermission(thisRole?.permission || []);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePermiss = async (permissionFieldName, permissionFunction) => {
    try {
      const checkExist = permission.find(
        (item) =>
          item.permissionFieldName == permission &&
          item.permissionFunction == permissionFunction
      );
      let permissions = [...permission];
      if (!checkExist) {
        permissions = [
          ...permissions,
          { permissionFieldName, permissionFunction },
        ];
      }
      const result = await updatePermission(selectedRole, {
        permission: permissions,
      });
      console.log(result);
      setPermission(result.permission);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (selectedRole) {
      getRole();
    }
  }, [selectedRole]);

  const columns = [
    {
      title: "Chức năng",
      dataIndex: "_id",
      render: (_, record) => {
        return <>{record.name}</>;
      },
    },
    {
      title: "Xem",
      dataIndex: "_id",
      render: (_, record) => {
        return (
          <Checkbox
            checked={permission.find(
              (item) =>
                (item.permissionFieldName == record.key &&
                  item.permissionFunction == permissionFunction.READ) ||
                (item.permissionFieldName == "manage" &&
                  item.permissionFunction == "all")
            )}
            disabled={permission.find(
              (item) =>
                item.permissionFieldName == "manage" &&
                item.permissionFunction == "all"
            )}
            onClick={() => updatePermiss(record.key, permissionFunction.READ)}
          />
        );
      },
    },
    {
      title: "Thêm",
      dataIndex: "_id",
      render: (_, record) => {
        return (
          <Checkbox
            checked={permission.find(
              (item) =>
                (item.permissionFieldName == record.key &&
                  item.permissionFunction == permissionFunction.CREATE) ||
                (item.permissionFieldName == "manage" &&
                  item.permissionFunction == "all")
            )}
            disabled={permission.find(
              (item) =>
                item.permissionFieldName == "manage" &&
                item.permissionFunction == "all"
            )}
            onClick={() => updatePermiss(record.key, permissionFunction.CREATE)}
          />
        );
      },
    },
    {
      title: "Sửa",
      dataIndex: "_id",
      render: (_, record) => {
        return (
          <Checkbox
            checked={permission.find(
              (item) =>
                (item.permissionFieldName == record.key &&
                  item.permissionFunction == permissionFunction.EDIT) ||
                (item.permissionFieldName == "manage" &&
                  item.permissionFunction == "all")
            )}
            disabled={permission.find(
              (item) =>
                item.permissionFieldName == "manage" &&
                item.permissionFunction == "all"
            )}
            onClick={() => updatePermiss(record.key, permissionFunction.EDIT)}
          />
        );
      },
    },
    {
      title: "Xóa",
      dataIndex: "_id",
      render: (_, record) => {
        return (
          <Checkbox
            checked={permission.find(
              (item) =>
                (item.permissionFieldName == record.key &&
                  item.permissionFunction == permissionFunction.DELETE) ||
                (item.permissionFieldName == "manage" &&
                  item.permissionFunction == "all")
            )}
            disabled={permission.find(
              (item) =>
                item.permissionFieldName == "manage" &&
                item.permissionFunction == "all"
            )}
            onClick={() => updatePermiss(record.key, permissionFunction.DELETE)}
          />
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <Select
          style={{ width: "150px" }}
          value={selectedRole}
          options={rolesGroup}
          placeholder="Chọn quyền hạn"
          allowClear
          onChange={(value) => setSelectedRole(value)}
        ></Select>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={Object.values(permissionFieldName)}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ManagePermission;
