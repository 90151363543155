export class User {
  constructor(
    _id,
    userName,
    password,
    fullName,
    avatar,
    phoneNumber,
    email,
    createdTime,
    updatedTime,
    role,
    activeStatus,
    bio,
    level,
    chatCount
  ) {
    this._id = _id;
    this.userName = userName;
    this.password = password;
    this.fullName = fullName;
    this.avatar = avatar;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.createdTime = createdTime;
    this.updatedTime = updatedTime;
    this.role = role;
    this.activeStatus = activeStatus;
    this.bio = bio;
    this.level = level;
    this.chatCount = chatCount;
  }
}
