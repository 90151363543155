export const permissionFieldName = {
  user: {
    key: "user",
    name: "Quản lí người dùng",
  },
  role: {
    key: "role",
    name: "Quản lí quyền",
  },
  room: {
    key: "room",
    name: "Quản lí room chat",
  },
  loginAdmin: {
    key: "admin",
    name: "Đăng nhập trang admin",
  },
};
