import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
    name: 'forwardingMessage',
    initialState: null,
    reducers: {
        setForwardingMessage: (state, action) => {
            state = action.payload;
            return state;
        }
    }
})

const { reducer, actions } = slice;
export const { setForwardingMessage } = actions;
export default reducer;