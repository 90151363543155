import * as React from "react";
import styles from "../../assets/styles/RoomInfo.module.css";
import { useEffect, useState } from "react";
import { Modal, ModalFooter, Offcanvas, Accordion } from "react-bootstrap";
import axios from "axios";
import {
  FileTypes,
  MessageTypes,
  Roles,
  RoomRoles,
  RoomTypes,
} from "../../common/const";
import defaultAvatar from "../../assets/images/default-avatar.webp";
import defaultGroup from "../../assets/images/default-group.webp";
import AddMember from "../Members/AddMember";
import ManageMember from "../Members/ManageMember";
import { Button, Dropdown, notification } from "antd";
import { BsThreeDots } from "react-icons/bs";
import checkPermission from "../../helpers/checkPermission";
import { permissionFieldName } from "../../helpers/permissionFieldName";
import { permissionFunction } from "../../helpers/permissionFunction";
import { banUser, deleteUserFromRoom } from "../../helpers/helper";
import checkRoomPermission from "../../helpers/checkRoomPermission";
import ModalPermission from "./ModalPermission";

export default function RoomInfo({
  user,
  room,
  setRoom,
  showUserInfoModal,
  socket,
}) {
  // const dispatch = useDispatch();

  const [modalPermission, setModalPermission] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [onlineMembers, setOnlineMembers] = useState(new Array());
  const [files, setFiles] = useState(new Array());
  const [isAddMemberModalShow, setAddMemberModalShow] = useState(false);
  const [isManageMemberModalShow, setIsManageMemberModalShow] = useState(false);
  const [showRightFileTab, setShowRightFileTab] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  // const onlineUsers = useSelector((state: any) => state.onlineUsers);
  // useEffect(() => {
  //     setOnlineMembers(onlineUsers);
  // }, [onlineUsers]);

  useEffect(() => {
    if (room._id) {
      axios.get(`/api/file/getAllByRoom/${room._id}`).then((response) => {
        if (response) {
          setFiles(response);
        }
      });
    }
  }, [room]);

  useEffect(() => {
    socket.on("receive_message", (data) => {
      if (room._id === data.room._id) {
        if (
          data.type === MessageTypes.IMAGE ||
          data.type === MessageTypes.VIDEO
        ) {
          setFiles((oldFiles) => [data.file, ...oldFiles]);
        }
      }
    });
  }, [room]);

  // function showUserInfo(user) {
  //     const action = setUserInfo(user);
  //     dispatch(action);
  //     setTimeout(() => {
  //         showUserInfoModal(true);
  //     }, 300);
  // }

  const items = [
    {
      key: "1",
      label: (
        <p
          style={{ marginBottom: "0px" }}
          onClick={() => setModalPermission(true)}
        >
          Phân quyền
        </p>
      ),
    },
    checkRoomPermission(room.canBan, room.roomOwner)
      ? {
          key: "2",
          label: <p style={{ marginBottom: "0px" }}>Chặn</p>,
        }
      : {},
    checkRoomPermission(room.canDelete, room.roomOwner)
      ? {
          key: "3",
          label: <p style={{ marginBottom: "0px" }}>Xóa</p>,
        }
      : {},
  ];

  const handleBanUser = async (userId) => {
    try {
      const result = await banUser(room._id, userId);
      api["success"]({
        message: "Ban thành công",
        description: "Ban user thành công",
      });
      setRoom({
        ...room,
        members: room.members?.filter((item) => item.user?._id != userId),
      });
    } catch (error) {
      console.log(error);
      api["error"]({
        message: "Ban thất bại",
        description: "Ban user thất bại",
      });
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const result = await deleteUserFromRoom(userId, room._id);
      api["success"]({
        message: "Xóa user thành công",
        description: "Xóa user thành công",
      });
      setRoom({
        ...room,
        members: room.members?.filter((item) => item.user?._id != userId),
      });
    } catch (error) {
      console.log(error);
      api["error"]({
        message: "Xóa user thất bại",
        description: "Xóa user thất bại",
      });
    }
  };

  const handleDropdownClick = (key, userId, user) => {
    if (key == 2) {
      handleBanUser(userId);
    }

    if (key == 3) {
      handleDeleteUser(userId);
    }

    if (key == 1) {
      setSelectedUser(user);
    }
  };

  const handleUpdateUserPermission = async () => {};

  return (
    <>
      <ModalPermission
        isOpen={modalPermission}
        onClose={() => {
          setModalPermission(false);
          setSelectedUser(null);
        }}
        selectedUser={selectedUser}
        onOk={handleUpdateUserPermission}
        room={room}
      />
      {contextHolder}
      <div className={styles.roomInfo}>
        <div className="bg-white">
          <div className="p-3">
            <h5 className="text-center m-0">Thông tin</h5>
          </div>
        </div>
        {room._id !== undefined ? (
          <>
            <div className="d-flex p-3 bg-white">
              <div className="rounded-circle">
                {room.roomType === RoomTypes.PERSONAL ? (
                  <img
                    className="rounded-circle"
                    width={55}
                    height={55}
                    src={
                      room.members.filter((x) => x.user._id !== user._id)[0]
                        .user.avatar
                        ? process.env.UPLOAD_FOLDER_URL +
                          room.members.filter((x) => x.user._id !== user._id)[0]
                            .user.avatar
                        : defaultAvatar
                    }
                    alt={room.roomName ?? "room-icon"}
                  />
                ) : (
                  <img
                    className="rounded-circle"
                    width={55}
                    height={55}
                    src={
                      room.roomIcon
                        ? process.env.UPLOAD_FOLDER_URL + room.roomIcon
                        : defaultGroup
                    }
                    alt={room.roomName ?? "room-icon"}
                  />
                )}
              </div>
              <div className="ms-2">
                {room.roomType === RoomTypes.PERSONAL ? (
                  <h5 className="allow-one-line m-0 fw-bold">
                    {
                      room.members.filter((x) => x.user._id !== user._id)[0]
                        .user.fullName
                    }
                  </h5>
                ) : (
                  <h5 className="allow-one-line m-0 fw-bold">
                    {room.roomName}
                  </h5>
                )}
                <div>
                  {
                    room.members?.filter((x) => x?.user?.userName !== "admin")
                      .length
                  }{" "}
                  thành viên
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2">
                <div className="p-3 bg-white">
                  <div
                    className={styles.file}
                    onClick={() => {
                      setShowRightFileTab(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-image"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                      </svg>
                    </span>
                    <span className="ms-2">Hình ảnh</span>
                    <span className="ms-2">
                      (
                      {
                        files.filter((x) => x?.fileType === FileTypes.IMAGE)
                          .length
                      }
                      )
                    </span>
                  </div>
                  <div
                    className={styles.file}
                    onClick={() => {
                      setShowRightFileTab(true);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-play-btn"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                      </svg>
                    </span>
                    <span className="ms-2">Video</span>
                    <span className="ms-2">
                      (
                      {
                        files.filter((x) => x?.fileType === FileTypes.VIDEO)
                          .length
                      }
                      )
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="ps-3 pt-3 pb-3 bg-white">
                <div className="d-flex justify-content-between pe-3">
                  <h5>Thành viên</h5>
                  <div className="ms-auto d-flex">
                    {room.roomType !== "personal" ? (
                      <>
                        <div
                          onClick={() => {
                            setAddMemberModalShow(true);
                          }}
                          className="cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-person-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            <path
                              fillRule="evenodd"
                              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                            />
                          </svg>
                        </div>
                        {user.userName === "admin" ||
                        user.role?.roleName === Roles.ADMIN ||
                        room.members.filter(
                          (member) => member?.user?._id === user._id
                        )[0]?.role === RoomRoles.OWNER ||
                        room.members.filter(
                          (member) => member?.user?._id === user._id
                        )[0]?.role === RoomRoles.ADMIN ? (
                          checkPermission(
                            permissionFieldName.room.key,
                            permissionFunction.EDIT
                          ) && (
                            <div
                              className="ms-3 cursor-pointer"
                              onClick={() => {
                                setIsManageMemberModalShow(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-gear"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                              </svg>
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className={styles.listMembers}>
                  {room.members
                    .filter((x) => x.user?.userName !== "admin")
                    ?.map((member) => (
                      <div
                        key={member.user._id}
                        className={"d-flex p-2 cursor-pointer " + styles.member}
                        // onClick={() => {
                        //   showUserInfo(member.user);
                        // }}
                      >
                        <div className="rounded-circle">
                          <img
                            className="rounded-circle"
                            width={40}
                            height={40}
                            src={
                              member.user?.avatar
                                ? process.env.UPLOAD_FOLDER_URL +
                                  member.user.avatar
                                : defaultAvatar
                            }
                            alt={member.user?.fullName ?? "user-avatar"}
                          />
                        </div>
                        <div className="ms-2 w-100">
                          <div className="w-100 d-flex justify-content-between">
                            <small className="fw-bold allow-one-line">
                              {member.user?.fullName}
                            </small>
                            {member.role === "owner" ? (
                              <small className="text-primary">
                                {member.role}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            {onlineMembers.filter(
                              (x) => x.user?._id === member.user._id
                            ).length > 0 ? (
                              <small className="text-primary d-block">
                                online
                              </small>
                            ) : (
                              <small className="text-muted d-block">
                                offline
                              </small>
                            )}
                            <Dropdown
                              menu={{
                                items,
                                onClick: ({ key }) => {
                                  handleDropdownClick(
                                    key,
                                    member.user._id,
                                    member.user
                                  );
                                },
                              }}
                              placement="bottom"
                            >
                              <BsThreeDots />
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <Modal
        show={isAddMemberModalShow}
        onHide={() => {
          setAddMemberModalShow(false);
        }}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
            <span className="ms-2">Thêm thành viên</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2 pe-0 pb-3">
          <AddMember
            user={user}
            room={room}
            onlineMembers={onlineMembers}
            setRoom={setRoom}
            socket={socket}
          />
        </Modal.Body>
        <ModalFooter>
          <input
            className="btn btn-secondary"
            type="button"
            value="Đóng"
            onClick={() => {
              setAddMemberModalShow(false);
            }}
          />
        </ModalFooter>
      </Modal>

      <Modal
        show={isManageMemberModalShow}
        onHide={() => {
          setIsManageMemberModalShow(false);
        }}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-people-fill"
              viewBox="0 0 16 16"
            >
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              <path
                fillRule="evenodd"
                d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
              />
              <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
            <span className="ms-2">Quản lý thành viên</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2 pe-0 pb-3">
          <ManageMember
            room={room}
            user={user}
            onlineMembers={onlineMembers}
            socket={socket}
            setRoom={setRoom}
          />
        </Modal.Body>
        <ModalFooter>
          <input
            className="btn btn-secondary"
            type="button"
            value="Đóng"
            onClick={() => {
              setIsManageMemberModalShow(false);
            }}
          />
        </ModalFooter>
      </Modal>

      <Offcanvas
        show={showRightFileTab}
        onHide={() => {
          setShowRightFileTab(false);
        }}
        placement="end"
        scroll={false}
        backdrop={true}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-bold">Files</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <Accordion>
            <Accordion.Item eventKey="images">
              <Accordion.Header>Hình ảnh</Accordion.Header>
              <Accordion.Body className="clearfix p-0">
                {files
                  .filter((x) => x?.fileType === FileTypes.IMAGE)
                  .map((file) => (
                    <div key={file._id} className={styles.fileItem}>
                      <div className="w-100 h-100 position-relative">
                        <img
                          fill
                          // sizes="(max-width: 768px) 100vw,
                          //                               (max-width: 1200px) 50vw,
                          //                               33vw"
                          style={{ width: "100%", height: "100%" }}
                          className={styles.image}
                          src={
                            (process.env.UPLOAD_FOLDER_URL ??
                              "http://localhost:5555/uploads") + file?.filePath
                          }
                          alt="image"
                        />
                      </div>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="videos">
              <Accordion.Header>Video</Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
