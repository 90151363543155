//login
export const API_USER_LOGIN = "/api/user/login";

//User
export const API_USER_INSERT = "/api/user/insert";
export const API_USER_UPDATE = "/api/user/update";
export const API_USER_DELETE = "/api/user/delete";
export const API_USER_GET_PAGING = "/api/user/getPaging";
export const API_USER_GET_PAGING_BY_ID = "/api/user/getById";

//Role
export const API_ROLE_INSERT = "/api/role/insert";
export const API_ROLE_UPDATE = "/api/role/update";
export const API_ROLE_DELETE = "/api/role/delete";
export const API_ROLE_GETALL = "/api/role/getAll";
export const API_ROLE_GET_PAGING = "/api/role/getPaging";
export const API_ROLE_GET_PAGING_BY_ID = "/api/role/getById";
export const API_ROLE_UPDATE_PERMISSION = "/api/role/update-permission";

//room

export const API_GET_ROOM = "/api/room/getPaging";
export const API_GET_ROOM_BY_ID = "/api/room/getById";
export const API_CREATE_ROOM = "/api/room/insert";
export const API_UPDATE_ROOM = "/api/room/update";
export const API_ROOM_DELETE = "/api/room/delete";
export const API_BAN_USER = "/api/room/banUser";
export const API_DELETE_USER = "/api/usersrooms/delete";

//log room
export const API_GET_LOG_ROOM = "/api/logroom/getPaging";
