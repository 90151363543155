export const textConfirmDelete = "Bạn có chắc chắn muốn xóa?";

export const UserStatus = [
  { value: 1, label: "Kích hoạt" },
  { value: 2, label: "Ngưng kích hoạt" },
];

export const RoomTypes = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  PERSONAL: 'personal',
  EMBED: 'embed'
}

export const RoomRoles = {
  ADMIN: 'admin',
  OWNER: 'owner'
}

export const Roles = {
  ADMIN: 'Admin',
  CUSTOMER: 'Customer'
}

export const MessageTypes = {
  NOTIFICATION: 'notification',
  MESSAGE: 'message',
  IMAGE: 'image',
  VIDEO: 'video',
  GIF: 'gif',
  VUVUZELA: 'vuvuzela',
  BOUQUET: 'bouquet',
  FIREWORKS: 'fireworks',
  ANIMATION_GIFT: 'ANIMATION_GIFT',
  MESSAGE_PIN: 'MESSAGE_PIN',
}

export const FileTypes = {
  IMAGE: 'image',
  VIDEO: 'video'
}
