import axios from 'axios';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover, Tab, Tabs } from 'react-bootstrap';
import styles from "../assets/styles/Chats.module.css";
import EmojiPicker, { EmojiStyle, SkinTones, Theme, Categories, EmojiClickData, Emoji, SuggestionMode } from "emoji-picker-react";
import toastr from 'toastr';
import { FileTypes, MessageTypes } from '../common/const';
import { Chat } from '../models/Chat'
import {useNavigate} from 'react-router-dom'

export default function Chats({ room, user, socket, dataToReply, dataToEdit, setIsShowReplyBox, setDataToReply, setIsShowEditBox, setDataToEdit }) {
    toastr.options.positionClass = 'toast-top-center';

    const [gifsState, setGifsState] = useState([]);
    const textareaRef = useRef(null);
    const imageRef = useRef(null);
    const gifRef = useRef(null);
    const navigate = useNavigate()

    const [emojiKey, setEmojiKey] = useState('emoji');

    // useEffect(() => {
    //     setGifsState(storedGifs);
    // }, [storedGifs]);

    function selectEmoji(emojiData, event) {
        if (textareaRef.current) {
            let selectionStart = textareaRef.current.selectionStart;
            let selectionEnd = textareaRef.current.selectionEnd;
            let emoji = emojiData.emoji;

            textareaRef.current.value =
                textareaRef.current.value.substring(0, selectionStart) +
                emoji +
                textareaRef.current.value.substring(selectionEnd);

            textareaRef.current.setSelectionRange(selectionStart + 2, selectionStart + 2);
            textareaRef.current.focus();
        }
    }

    function resizeTextArea(event) {
        event.target.style.height = event.target.scrollHeight + 'px';
        let numberOfLineBreak = (event.target.value.match(/\n/g) || []).length;
        if (numberOfLineBreak === 0) {
            event.target.style.height = '40px';
        }
    }

    async function sendMessage(message, replyTo) {
        try {
            let dataToInsert = {}
            if (replyTo) {
                dataToInsert = { message: message, room: room._id, type: MessageTypes.MESSAGE, replyTo: replyTo._id }
            }
            else {
                dataToInsert = { message: message, room: room._id, type: MessageTypes.MESSAGE }
            }

            let response = await axios.post('/api/chat/insert', dataToInsert);
            console.log('response', response)
            if (response.status === 1) {
                let newChat = {};
                if (response.replyTo) {
                    newChat = new Chat(user, response.data.message, room, MessageTypes.MESSAGE, response.data.createdTime, response.data._id, response.data.lastModified, response.data.replyTo);
                }
                else {
                    newChat = new Chat(user, response.data.message, room, MessageTypes.MESSAGE, response.data.createdTime, response.data._id, response.data.lastModified);
                }
                console.log('new chat', newChat)
                socket.emit('send_message', newChat);
            }
        } catch (error) {
            console.log('dsadas', error)
            if (error.response?.status === 403) {
                toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                navigate('/login')
            }
            else if (error.response?.status === 401) {
                toastr.error('Bạn không có quyền thực hiện thao tác này!');
            }
        }
    }

    async function editMessage(message, dataToEdit) {
        if (dataToEdit) {
            try {
                dataToEdit.message = message;
                let response = await axios.put(`/api/chat/update/${dataToEdit._id}`, dataToEdit);
                if (response.data.status === 1) {
                    socket.emit('edit_message', response.data.data);
                }
            } catch (error) {
                if (error.response?.status === 403) {
                    toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                    navigate('/login');
                }
                else if (error.response?.status === 401) {
                    toastr.error('Bạn không có quyền thực hiện thao tác này!');
                }
            }
        }
    }

    async function handleSendMessage(event) {
        event.preventDefault();
        if (event.target.message.value) {
            if (dataToReply) {
                sendMessage(event.target.message.value, dataToReply);
            }
            else {
                sendMessage(event.target.message.value);
            }
            event.target.message.value = '';
            event.target.message.style.height = '40px';
            setDataToReply({ user: { fullName: '' }, message: '' });
            setIsShowReplyBox(false);
        }
    }

    async function handleSendMessageOnEnter(event) {
        if (event.which === 13 && !event.shiftKey) {
            event.preventDefault();
            if (event.target.value) {
                if (dataToReply) {
                    sendMessage(event.target.value, dataToReply);
                }
                else {
                    sendMessage(event.target.value);
                }
                event.target.value = '';
                event.target.style.height = '40px';
                setDataToReply({ user: { fullName: '' }, message: '' });
                setIsShowReplyBox(false);
            }
        }
    }

    async function handleEditMessage(event) {
        event.preventDefault();
        if (event.target.messageToEdit.value) {
            if (dataToEdit) {
                editMessage(event.target.messageToEdit.value, dataToEdit);
            }
            else {
                editMessage(event.target.messageToEdit.value);
            }
            event.target.messageToEdit.value = '';
            event.target.messageToEdit.style.height = '40px';
            setDataToEdit({ message: '' });
            setIsShowEditBox(false);
        }
    }

    async function handleEditMessageOnEnter(event) {
        if (event.which === 13 && !event.shiftKey) {
            event.preventDefault();
            if (event.target.value) {
                if (dataToReply) {
                    editMessage(event.target.value, dataToEdit);
                }
                else {
                    editMessage(event.target.value);
                }
                event.target.value = '';
                event.target.style.height = '40px';
                setDataToEdit({ message: '' });
                setIsShowEditBox(false);
            }
        }
    }

    useEffect(() => {
        textareaRef.current?.focus();
    }, [dataToReply]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.value = dataToEdit.message;
            textareaRef.current.setSelectionRange(dataToEdit.message.length, dataToEdit.message.length);
        }
    }, [dataToEdit]);

    function sendImage() {
        imageRef.current?.click();
    }

    async function imageOnChange(event) {
        event.stopPropagation();
        event.preventDefault();

        let file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            try {
                let response = await axios.post('/api/upload/uploadImage', formData, {
                    headers: { "content-type": "application/x-www-form-urlencoded" }
                });

                if (response.status === 1) {
                    let savedFileInfo = await axios.post('/api/file/insert', { room: room, filePath: response.data, fileType: FileTypes.IMAGE });
                    console.log('savedFileInfo', savedFileInfo)
                    if (savedFileInfo.status === 1) {

                        let dataToInsert = { message: 'image', room: room._id, type: MessageTypes.IMAGE, file: savedFileInfo.data._id }
                        let savedChat = await axios.post('/api/chat/insert', dataToInsert);
                        console.log('savedChat', savedChat)
                        let newChat = new Chat(user, 'image', room, MessageTypes.IMAGE, savedChat.data.createdTime, savedChat.data._id, undefined, undefined, savedChat.data.file);
                        socket.emit('send_message', newChat);
                    }
                }
            } catch (error) {
                if (error.response?.status === 403) {
                    toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                    navigate('/login')
                }
                else if (error.response?.status === 401) {
                    toastr.error('Bạn không có quyền thực hiện thao tác này!');
                }
            }
        }
    }

    function sendGif() {
        gifRef.current?.click();
    }

    async function gifOnChange(event) {
        event.stopPropagation();
        event.preventDefault();

        let file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            try {
                let response = await axios.post('/api/upload/uploadImage', formData)
                if (response.data.status === 1) {
                    let savedFileInfo = await axios.post('/api/file/insert', { room: room, filePath: response.data, fileType: FileTypes.IMAGE });
                    if (savedFileInfo.status === 1) {

                        let dataToInsert = { message: 'gif', room: room._id, type: MessageTypes.GIF, file: savedFileInfo.data._id }
                        let savedChat = await axios.post('/api/chat/insert', dataToInsert);
                        console.log('savedChat', savedChat)
                        if (savedChat.data) {
                            // let newChat = new Chat(user, 'gif', room, MessageTypes.GIF, new Date(), savedChat.data.data._id, undefined, undefined, savedChat.data.data.file);
                            // socket.emit('send_message', newChat);
                        }
                    }
                }
            } catch (error) {
                if (error.response?.status === 403) {
                    toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                    navigate('/login')
                }
                else if (error.response?.status === 401) {
                    toastr.error('Bạn không có quyền thực hiện thao tác này!');
                }
            }
        }
    }

    async function sendGifInGifBox(gif) {
        try {
            let dataToInsert = { message: 'gif', room: room._id, type: MessageTypes.GIF, file: gif.file._id }
            let savedChat = await axios.post('/api/chat/insert', dataToInsert);
            if (savedChat.data) {
                // let newChat = new Chat(user, 'gif', room, MessageTypes.GIF, new Date(), savedChat.data.data._id, undefined, undefined, savedChat.data.data.file);
                // socket.emit('send_message', newChat);
            }
        } catch (error) {
            if (error.response?.status === 403) {
                toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                // router.push('/login')
            }
            else if (error.response?.status === 401) {
                toastr.error('Bạn không có quyền thực hiện thao tác này!');
            }
        }

    }

    async function removeGif(gif) {
        try {
            if (gif._id) {
                let response = await axios.delete(`/api/gif/delete/${gif._id}`);
                if (response.data.status === 1) {
                    // const action = deleteGif(response.data.data);
                    // dispatch(action);
                    toastr.success('Xóa gif thành công!');
                }
                else if (response.data.status === 0) {
                    toastr.error('Gif hiện không tồn tại!');
                }
            }
        } catch (error) {
            if (error.response?.status === 403) {
                toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
                // router.push('/login')
            }
            else if (error.response?.status === 401) {
                toastr.error('Bạn không có quyền thực hiện thao tác này!');
            }
        }
    }

    return (
        <>
            {
                (room._id !== undefined) ? (
                    <div className={'d-flex justify-content-around bg-white border p-2 ' + styles.messageArea}>
                        <div className={styles.fileAttach}>
                            <div className='text-muted me-3 cursor-pointer' onClick={sendImage}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-image" viewBox="0 0 16 16">
                                    <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z" />
                                </svg>
                            </div>
                            <div className='text-muted me-3 cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-play" viewBox="0 0 16 16">
                                    <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                </svg>
                            </div>
                            <div className='text-muted me-3 cursor-pointer' onClick={sendGif}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-filetype-gif" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2H9v-1h3a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.278 13.124a1.403 1.403 0 0 0-.14-.492 1.317 1.317 0 0 0-.314-.407 1.447 1.447 0 0 0-.48-.275 1.88 1.88 0 0 0-.636-.1c-.361 0-.67.076-.926.229a1.48 1.48 0 0 0-.583.632 2.136 2.136 0 0 0-.199.95v.506c0 .272.035.52.105.745.07.224.177.417.32.58.142.162.32.288.533.377.215.088.466.132.753.132.268 0 .5-.037.697-.111a1.29 1.29 0 0 0 .788-.77c.065-.174.097-.358.097-.551v-.797H1.717v.589h.823v.255c0 .132-.03.254-.09.363a.67.67 0 0 1-.273.264.967.967 0 0 1-.457.096.87.87 0 0 1-.519-.146.881.881 0 0 1-.305-.413 1.785 1.785 0 0 1-.096-.615v-.499c0-.365.078-.648.234-.85.158-.2.38-.301.665-.301a.96.96 0 0 1 .3.044c.09.03.17.071.236.126a.689.689 0 0 1 .17.19.797.797 0 0 1 .097.25h.776Zm1.353 2.801v-3.999H3.84v4h.79Zm1.493-1.59v1.59h-.791v-3.999H7.88v.653H6.124v1.117h1.605v.638H6.124Z" />
                                </svg>
                            </div>
                            <input type="file" style={{ display: 'none' }} ref={imageRef} accept='.jpg,.png,.webp,.jpeg' onChange={imageOnChange} />
                            <input type="file" style={{ display: 'none' }} ref={gifRef} accept='.gif' onChange={gifOnChange} />
                        </div>
                        {
                            (dataToEdit._id) ? (
                                //Edit message
                                <form className='col-11 d-flex justify-content-around' onSubmit={handleEditMessage} onKeyDownCapture={handleEditMessageOnEnter}>
                                    <div className={styles.messageFieldContainer}>
                                        <textarea name='messageToEdit'
                                            placeholder='Nhập tin nhắn'
                                            rows={1}
                                            className={'form-control overflow-hidden ' + styles.messageField}
                                            onChange={resizeTextArea}
                                            ref={textareaRef}
                                        />
                                    </div>
                                    <div className={styles.sendArea}>
                                        <div className='w-100 d-flex justify-content-start'>
                                            <OverlayTrigger
                                                trigger={'click'}
                                                placement='top'
                                                offset={[0, 25]}
                                                overlay={
                                                    <Popover
                                                        style={{ maxWidth: '600px' }}>
                                                        <Popover.Body className='p-0'>
                                                            <Tabs
                                                                activeKey={emojiKey}
                                                                onSelect={(k) => setEmojiKey(k ?? 'emoji')}
                                                                fill
                                                            >
                                                                <Tab eventKey="emoji" title="Emoji">
                                                                    <EmojiPicker
                                                                        onEmojiClick={selectEmoji}
                                                                        autoFocusSearch={false}
                                                                        theme={Theme.LIGHT}
                                                                        emojiStyle={EmojiStyle.TWITTER}
                                                                        lazyLoadEmojis={true}
                                                                        skinTonesDisabled={true}
                                                                        previewConfig={
                                                                            {
                                                                                showPreview: false
                                                                            }
                                                                        }
                                                                    />
                                                                </Tab>
                                                                <Tab eventKey="sticker" title="Sticker">
                                                                    <div className={styles.stickerTab}>

                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="gif" title="Gif">
                                                                    <div className={styles.gifTab}>
                                                                    </div>
                                                                </Tab>
                                                            </Tabs>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <div className={'cursor-pointer text-secondary ' + styles.emotionIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                                                    </svg>
                                                </div>
                                            </OverlayTrigger>
                                            <button type='submit' className={'border-0 bg-transparent p-0 text-primary ' + styles.sendButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className={'bi bi-send-fill ' + styles.sendIcon} viewBox="0 0 16 16">
                                                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                //Send message
                                <form className='col-11 d-flex justify-content-around' onSubmit={handleSendMessage} onKeyDownCapture={handleSendMessageOnEnter}>
                                    <div className={styles.messageFieldContainer}>
                                        <textarea name='message'
                                            placeholder='Nhập tin nhắn'
                                            rows={1}
                                            className={'form-control overflow-hidden ' + styles.messageField}
                                            onChange={resizeTextArea}
                                            ref={textareaRef}
                                        />
                                    </div>
                                    <div className={styles.sendArea}>
                                        <div className='w-100 d-flex justify-content-start'>
                                            <OverlayTrigger
                                                trigger={'click'}
                                                placement='top'
                                                offset={[0, 25]}
                                                overlay={
                                                    <Popover
                                                        style={{ maxWidth: '600px' }}>
                                                        <Popover.Body className='p-0'>
                                                            <Tabs
                                                                activeKey={emojiKey}
                                                                onSelect={(k) => setEmojiKey(k ?? 'emoji')}
                                                                fill
                                                            >
                                                                <Tab eventKey="emoji" title="Emoji">
                                                                    <EmojiPicker
                                                                        onEmojiClick={selectEmoji}
                                                                        autoFocusSearch={false}
                                                                        theme={Theme.LIGHT}
                                                                        emojiStyle={EmojiStyle.TWITTER}
                                                                        lazyLoadEmojis={true}
                                                                        skinTonesDisabled={true}
                                                                        previewConfig={
                                                                            {
                                                                                showPreview: false
                                                                            }
                                                                        }
                                                                    />
                                                                </Tab>
                                                                <Tab eventKey="sticker" title="Sticker">
                                                                    <div className={styles.stickerTab}>

                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="gif" title="Gif">
                                                                    <div className={styles.gifTab}>
                                                                        {
                                                                            (gifsState.length > 0) ? (
                                                                                <>
                                                                                    <div className='clearfix'>
                                                                                        {
                                                                                            gifsState.filter(x => x.defaultGif === false).map((gif) => (
                                                                                                <div className={styles.gifItemContainer + ' col-4 float-start position-relative'} key={gif._id}>
                                                                                                    <img
                                                                                                        className={styles.gifItem}
                                                                                                        width={114}
                                                                                                        height={114}
                                                                                                        src={process.env.UPLOAD_FOLDER_URL + gif.file?.filePath}
                                                                                                        alt='gif'
                                                                                                        onClick={() => { document.body.click(); sendGifInGifBox(gif) }}
                                                                                                    />
                                                                                                    <input type="button" className={styles.btnDeleteGif + ' btn-close'} onClick={() => { removeGif(gif) }} />
                                                                                                </div>
                                                                                            ))
                                                                                        }

                                                                                    </div>
                                                                                    <div className='bg-light text-center text-muted pt-1 pb-1'>Gifs mặc định</div>
                                                                                    <div className='clearfix'>
                                                                                        {
                                                                                            gifsState.filter(x => x.defaultGif === true).map((gif) => (
                                                                                                <div className={styles.gifItemContainer + ' col-4 float-start position-relative'} key={gif._id}>
                                                                                                    <img
                                                                                                        className={styles.gifItem}
                                                                                                        width={114}
                                                                                                        height={114}
                                                                                                        src={process.env.UPLOAD_FOLDER_URL + gif.file?.filePath}
                                                                                                        alt='gif'
                                                                                                        onClick={() => { document.body.click(); sendGifInGifBox(gif) }}
                                                                                                    />
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            ) : (<></>)
                                                                        }
                                                                    </div>
                                                                </Tab>
                                                            </Tabs>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <div className={'cursor-pointer text-secondary ' + styles.emotionIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                                                    </svg>
                                                </div>
                                            </OverlayTrigger>

                                            <button type='submit' className={'border-0 bg-transparent p-0 text-primary ' + styles.sendButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className={'bi bi-send-fill ' + styles.sendIcon} viewBox="0 0 16 16">
                                                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                ) : (<></>)
            }
        </>
    );
}