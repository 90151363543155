import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../assets/styles/Messages.module.css";
import { Modal, ModalFooter, OverlayTrigger, Popover } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import toastr from "toastr";
import "toastr/build/toastr.css";
import { MessageTypes } from "../../common/const";
import { formatDate } from "../../common/function";
import axios from "axios";
import defaultAvatar from "../../assets/images/default-avatar.webp";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/users/userInfoSlice";
import { setForwardingMessage } from "../../redux/messages/forwardingMessageSlice";
import ForwardingMessage from "./ForwardingMessage";
import checkRoomPermission from "../../helpers/checkRoomPermission";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    confettiWidth: 0,
    confittiHeight: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        confettiWidth: window.innerWidth,
        confittiHeight: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export default function Messages({
  room,
  user,
  socket,
  clickReplyButton,
  clickEditButton,
  showUserInfoModal,
  setRoom,
}) {
  toastr.options.positionClass = "toast-top-center";

  const { confettiWidth, confittiHeight } = useWindowSize();

  const [hasMore, setHasMore] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowImageZoom, setIsShowImageZoom] = useState(false);
  const [isShowForwardModal, setIsShowForwardModal] = useState(false);

  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState(false);
  const [messages, setMessages] = useState([]);
  const [lastSeenMessage, setLastSeenMessage] = useState(null);
  const [zoomedInImageURL, setZoomedInImageURL] =
    useState("/default-image.jpg");
  const [refetch, setRefetch] = useState(false);

  const messageRefs = useRef([]);
  const messageContainerRef = useRef(null);
  const newMessageRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (room._id) {
      let member = room.members.filter((x) => x.user?._id === user?._id);

      socket.connect();
      socket.emit("user_connect", user);
      if (member[0]?.lastSeenMessage) {
        setIsShowLoading(true);
        axios
          .get(
            `/api/chat/getByLastSeenMessage?room=${room._id}&lastSeenMessage=${member[0].lastSeenMessage._id}`
          )
          .then(async (response) => {
            if (response.messages?.length > 0) {
              // console.log("ccc",response);
              let sortedMessages = response?.messages?.sort((a, b) => {
                if (a.createdTime < b.createdTime) {
                  return -1;
                }
                if (a.createdTime > b.createdTime) {
                  return 1;
                }
                return 0;
              });
              // console.log("sortedMessages", sortedMessages);
              await setMessages(sortedMessages || []);
              await setTotalRecords(response?.totalRecords || 0);
              setTimeout(() => {
                setIsShowLoading(false);
              }, 300);
            }
          })
          .catch((error) => {
            if (error.response?.status === 403) {
              toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
              //   router.push('/login')
            } else if (error.response?.status === 401) {
              toastr.error("Bạn không có quyền thực hiện thao tác này!");
            }
          })
          .finally(() => {
            setTimeout(() => {
              if (member?.length > 0) {
                messageRefs?.current?.forEach((messageRef, index) => {
                  if (messageRef) {
                    let messageItemData = JSON.parse(
                      messageRef.getAttribute("message-item")
                    );
                    if (
                      messageItemData._id === member?.[0]?.lastSeenMessage?._id
                    ) {
                      if (index === messageRefs.current.length) {
                        messageRefs.current[index]?.scrollIntoView();
                      } else {
                        if (index - 4 > 0) {
                          messageRefs.current[index - 4]?.scrollIntoView();
                        } else {
                          messageRefs.current[index]?.scrollIntoView();
                        }
                      }
                    }
                  }
                });
              }
            }, 300);
          });
      } else {
        axios
          .get(`/api/chat/getByLastSeenMessage?room=${room._id}`)
          .then(async (response) => {
            // console.log("222", response);
            if (response) {
              let sortedMessages = response.messages.sort((a, b) => {
                if (a.createdTime < b.createdTime) {
                  return -1;
                }
                if (a.createdTime > b.createdTime) {
                  return 1;
                }
                return 0;
              });
              await setMessages(sortedMessages);
              await setTotalRecords(response.totalRecords);
              setTimeout(() => {
                setIsShowLoading(false);
              }, 300);
            }
          })
          .catch((error) => {
            if (error.response?.status === 403) {
              toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
              //   router.push('/login')
            } else if (error.response?.status === 401) {
              toastr.error("Bạn không có quyền thực hiện thao tác này!");
            }
          })
          .finally(() => {
            setTimeout(() => {
              if (member?.length > 0) {
                messageRefs.current.forEach((messageRef, index) => {
                  if (messageRef) {
                    let messageItemData = JSON.parse(
                      messageRef.getAttribute("message-item")
                    );
                    if (
                      messageItemData._id === member[0].lastSeenMessage?._id
                    ) {
                      if (index === messageRefs.current.length) {
                        messageRefs.current[index]?.scrollIntoView();
                      } else {
                        if (index - 4 > 0) {
                          messageRefs.current[index - 4]?.scrollIntoView();
                        } else {
                          messageRefs.current[index]?.scrollIntoView();
                        }
                      }
                    }
                  }
                });
              }
            }, 300);
          });
      }

      socket.on("receive_message", async (data) => {
        console.log("receive_message", data);
        if (room._id === data.room._id) {
          if (data.type !== MessageTypes.VUVUZELA) {
            await setMessages((list) => [...list, data]);
            if (data.user?._id === user._id) {
              setTimeout(() => {
                scrollToNewMessage();
              }, 150);
            } else {
              let scrollHeight = messageContainerRef.current?.scrollHeight ?? 0;
              let scrollTop = messageContainerRef.current?.scrollTop ?? 0;
              let bottom =
                scrollHeight - scrollTop ===
                messageContainerRef.current?.clientHeight;
              if (bottom === true) {
                setTimeout(() => {
                  scrollToNewMessage();
                }, 150);
              } else {
                if (!showScrollToBottomButton) {
                  setShowScrollToBottomButton(true);
                }
              }
            }
          }
        }
      });

      socket.on("receive_edited_message", (data) => {
        setMessages((oldMessages) => {
          let newMessages = oldMessages.map((x) => {
            if (x._id === data._id) {
              x = data;
            }
            return x;
          });
          return newMessages;
        });
      });

      socket.on("deleted_message", async (data) => {
        if (data) {
          setMessages((list) => {
            return list.filter((x) => x._id !== data._id);
          });
        }
      });
    }

    return () => {
      socket.off("receive_message");
      socket.off("receive_edited_message");
      socket.off("deleted_message");
    };
  }, [room, refetch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefetch(!refetch);
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  //   const storedSearchingMessage = useSelector((state) => state.searchingMessage);

  //   useEffect(() => {
  //       if (room._id && storedSearchingMessage) {
  //           setIsShowLoading(true);
  //           axios.get(`/api/chat/getByLastSeenMessage?room=${room._id}&lastSeenMessage=${storedSearchingMessage._id}`)
  //               .then(async (response: any) => {
  //                   if (response.data) {
  //                       let sortedMessages = response.data.messages.sort((a: Chat, b: Chat) => {
  //                           if (a.createdTime < b.createdTime) {
  //                               return -1;
  //                           }
  //                           if (a.createdTime > b.createdTime) {
  //                               return 1;
  //                           }
  //                           return 0;
  //                       });
  //                       await setMessages(sortedMessages);
  //                       await setTotalRecords(response.data.totalRecords)
  //                       setTimeout(() => {
  //                           setIsShowLoading(false);
  //                       }, 300);
  //                   }
  //               })
  //               .catch((error: any) => {
  //                   if (error.response?.status === 403) {
  //                       toastr.error('Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!');
  //                       router.push('/login')
  //                   }
  //                   else if (error.response?.status === 401) {
  //                       toastr.error('Bạn không có quyền thực hiện thao tác này!');
  //                   }
  //               });

  //           setTimeout(() => {
  //               messageRefs.current.forEach((messageRef: any, index: number) => {
  //                   if (messageRef) {
  //                       let messageItemData = JSON.parse(messageRef.getAttribute('message-item'))
  //                       if (messageItemData._id === storedSearchingMessage._id) {
  //                           if (index === messageRefs.current.length) {
  //                               messageRefs.current[index]?.scrollIntoView();
  //                           }
  //                           else {
  //                               if (index - 4 > 0) {
  //                                   messageRefs.current[index - 4]?.scrollIntoView();
  //                               }
  //                               else {
  //                                   messageRefs.current[index]?.scrollIntoView();
  //                               }
  //                           }
  //                           messageRefs.current[index]?.classList.add('highlightMessage');
  //                           setTimeout(() => {
  //                               messageRefs.current[index]?.classList.remove('highlightMessage');
  //                           }, 1000)
  //                       }
  //                   }
  //               })
  //           }, 1000);
  //       }
  //   }, [storedSearchingMessage])

  //   useEffect(() => {
  //       let seenMessagesData = [];
  //       const options = {
  //           root: messageContainerRef.current,
  //           rootMargin: '0px',
  //           threshold: 1.0
  //       }
  //       const lastSeenMessageCallback = (entries) => {
  //           entries.forEach((entry, i) => {
  //               if (entry.isIntersecting) {
  //                   let messageItem = JSON.parse(entry.target.attributes['message-item'].value);
  //                   if (!(seenMessagesData.filter(x => x._id === messageItem._id).length > 0)) {
  //                       seenMessagesData.push(messageItem);
  //                   }
  //               }
  //           });
  //           if (seenMessagesData.length > 0) {
  //               let sortedMessages = seenMessagesData.sort((a: any, b: any) => {
  //                   if (a.createdTime < b.createdTime) {
  //                       return -1;
  //                   }
  //                   if (a.createdTime > b.createdTime) {
  //                       return 1;
  //                   }
  //                   return 0;
  //               });
  //               setLastSeenMessage(sortedMessages[sortedMessages.length - 1])
  //           }
  //       }
  //       const lastSeenMessageObserver = new IntersectionObserver(lastSeenMessageCallback, options);
  //       messageRefs.current.forEach((messageRef: any) => {
  //           if (messageRef) {
  //               lastSeenMessageObserver.observe(messageRef);
  //           }
  //       });

  //       //Unobserve
  //       return () => {
  //           messageRefs.current.forEach((messageRef: any) => {
  //               if (messageRef) {
  //                   lastSeenMessageObserver.unobserve(messageRef);
  //               }
  //           });
  //       }
  //   }, [messages]);

  useEffect(() => {
    if (room._id && lastSeenMessage) {
      setTimeout(() => {
        let seenMessage = {
          room: room._id,
          lastSeenMessage: lastSeenMessage,
        };
        axios.put("/api/usersrooms/updateLastSeenMessage", seenMessage);
      }, 300);
    }
  }, [lastSeenMessage]);

  function scrollToNewMessage() {
    newMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    setShowScrollToBottomButton(false);
  }

  async function deleteMessage(chat) {
    confirmAlert({
      title: "",
      message: "Bạn có chắc chắn muốn xóa?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await axios.delete(`/api/chat/delete/${chat._id}`);
              console.log("deleteMessage", response);
              if (response.status === 1) {
                if (
                  chat.type === MessageTypes.IMAGE ||
                  chat.type === MessageTypes.VIDEO
                ) {
                  let deletedFile = await axios.delete(
                    `/api/file/delete/${chat.file?._id}`
                  );
                  if (deletedFile.status === 1) {
                    await axios.delete(
                      `/api/upload/deleteImage/${deletedFile.data.data.filePath}`
                    );
                  }
                }
                setMessages((oldMessages) => {
                  return oldMessages.filter((x) => x._id !== chat._id);
                });
                toastr.success("Xóa tin nhắn thành công!");
                socket.emit("delete_message", chat);
              } else if (response.status === 0) {
                toastr.warning("Tin nhắn không tồn tại!");
              }
            } catch (error) {
              if (error.response?.status === 401) {
                toastr.error("Bạn không có quyền xóa tin nhắn này!");
              }
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function jumpToMessage(messageId) {
    if (messageId) {
      messageRefs.current.forEach((messageRef, index) => {
        if (messageRef) {
          let messageItemData = JSON.parse(
            messageRef.getAttribute("message-item")
          );
          if (messageItemData._id === messageId) {
            if (index - 4 > 0) {
              messageRefs.current[index - 4]?.scrollIntoView({
                behavior: "smooth",
              });
            } else {
              messageRefs.current[index]?.scrollIntoView({
                behavior: "smooth",
              });
            }
          }
        }
      });
    }
  }

  function showUserInfo(user) {
    if (user) {
      console.log("showUserInfo", user);
      const action = setUserInfo(user);
      dispatch(action);
      setTimeout(() => {
        showUserInfoModal(true);
      }, 300);
    }
  }

  function zoomInImage(imageUrl) {
    setZoomedInImageURL(imageUrl);
    setIsShowImageZoom(true);
  }

  async function saveGif(file) {
    try {
      if (file._id) {
        let response = await axios.post("/api/gif/insert", { file: file._id });
        if (response.data.status === 1) {
          //   const action = setGifs([{ ...response.data.data }]);
          //   dispatch(action);
          toastr.success("Lưu gif thành công!");
        } else if (response.data.status === 0) {
          toastr.warning("Gif này đã được lưu!");
        }
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toastr.error("Phiên đăng nhập quá hạn, vui lòng đăng nhập lại!");
        //   router.push('/login')
      } else if (error.response?.status === 401) {
        toastr.error("Bạn không có quyền thực hiện thao tác này!");
      }
    }
  }

  async function forwardMessage(chat) {
    const action = setForwardingMessage(chat);
    dispatch(action);
    setIsShowForwardModal(true);
  }

  return (
    <>
      <div className="position-relative">
        {room._id && messages?.length > 0 ? (
          <>
            <div className={styles.messagesContainer} ref={messageContainerRef}>
              {messages.map((data, index) =>
                data.type === MessageTypes.NOTIFICATION ? (
                  <div
                    key={data._id}
                    message-item={JSON.stringify({
                      _id: data._id,
                      createdTime: data.createdTime,
                    })}
                    ref={(x) => (messageRefs.current[index] = x)}
                  >
                    <div className={styles.notification}>
                      <small className="d-inline allow-one-line">
                        {data.message}
                      </small>
                    </div>
                  </div>
                ) : (
                  <div
                    key={data._id}
                    message-item={JSON.stringify({
                      _id: data._id,
                      createdTime: data.createdTime,
                    })}
                    ref={(x) => (messageRefs.current[index] = x)}
                  >
                    {user._id === data.user?._id ? (
                      //From me messages
                      <>
                        {data.replyTo ? (
                          <>
                            <div className={styles.rightMessageItem}>
                              <div className={styles.overlayTrigger}>
                                <OverlayTrigger
                                  trigger={"click"}
                                  placement="left"
                                  rootClose //hide when click outside
                                  overlay={
                                    <Popover>
                                      <Popover.Body className="d-flex pt-1 pb-2 ps-3 pe-3">
                                        <div
                                          className="text-danger cursor-pointer"
                                          title="Xóa"
                                          onClick={() => {
                                            document.body.click();
                                            deleteMessage(data);
                                          }}
                                        >
                                          Xóa, gỡ bỏ
                                        </div>
                                        <div
                                          className="ms-3 text-success cursor-pointer"
                                          title="Sửa"
                                          onClick={() => {
                                            document.body.click();
                                            clickEditButton(data);
                                          }}
                                        >
                                          Sửa
                                        </div>
                                        <div
                                          className="ms-3 text-primary cursor-pointer"
                                          title="Chuyển tiếp"
                                          onClick={() => {
                                            document.body.click();
                                            forwardMessage(data);
                                          }}
                                        >
                                          Chuyển tiếp
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <div
                                    className={styles.expandActions + " me-1"}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      className="bi bi-three-dots"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                    </svg>
                                  </div>
                                </OverlayTrigger>
                                <div
                                  className={styles.replyButton + " me-2"}
                                  title="Trả lời"
                                  onClick={() => {
                                    clickReplyButton(data);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-reply-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                  </svg>
                                </div>
                              </div>
                              {data.type === MessageTypes.MESSAGE ? (
                                <div
                                  className={
                                    styles.message + " " + styles.fromMe
                                  }
                                  ref={newMessageRef}
                                >
                                  {messages[index - 1]?.user?._id !==
                                  data.user?._id ? (
                                    <div
                                      className="allow-one-line text-success cursor-pointer fw-bold mb-1"
                                      onClick={() => {
                                        showUserInfo(data.user);
                                      }}
                                    >
                                      {data.user.fullName}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    className="border-start ps-1 mb-2 border-2 border-success cursor-pointer"
                                    onClick={() =>
                                      jumpToMessage(data.replyTo?._id)
                                    }
                                  >
                                    <small className="d-block allow-one-line fw-bold text-muted">
                                      {data.replyTo.user?.fullName}
                                    </small>
                                    {data.replyTo.type ===
                                    MessageTypes.MESSAGE ? (
                                      <small className="d-block allow-one-line text-muted">
                                        {data.replyTo.message}
                                      </small>
                                    ) : (
                                      <small className="d-block allow-one-line text-muted">
                                        Hình ảnh
                                      </small>
                                    )}
                                  </div>
                                  <div>
                                    <div className={styles.messageText}>
                                      {data.message}
                                    </div>
                                    <div className="text-end text-muted">
                                      {data.lastModified &&
                                      data.lastModified > data.createdTime ? (
                                        <>
                                          <small>(Đã sửa)</small>
                                          <small className="ms-1">
                                            {formatDate(data.lastModified)}
                                          </small>
                                        </>
                                      ) : (
                                        <small className="ms-1">
                                          {formatDate(data.createdTime)}
                                        </small>
                                      )}
                                      <small className="ms-1 text-success">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="currentColor"
                                          className="bi bi-check2"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {data.type === MessageTypes.IMAGE ? (
                                    <div
                                      className={styles.image}
                                      onClick={() =>
                                        zoomInImage(
                                          (process.env.UPLOAD_FOLDER_URL ??
                                            "http://localhost:5555/uploads") +
                                            data.file?.filePath
                                        )
                                      }
                                    >
                                      <img
                                        className="w-100"
                                        src={
                                          (process.env.UPLOAD_FOLDER_URL ??
                                            "http://localhost:5555/uploads") +
                                          data.file?.filePath
                                        }
                                        alt="image"
                                      />
                                      <div className={styles.imageTime}>
                                        <small>
                                          {formatDate(data.createdTime)}
                                        </small>
                                        <small className="ms-1 text-white">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            fill="currentColor"
                                            className="bi bi-check2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                          </svg>
                                        </small>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {data.type === MessageTypes.VIDEO ? (
                                        <div>
                                          <video>
                                            <source />
                                          </video>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.image + " " + styles.gif
                                          }
                                        >
                                          <img
                                            className="w-100"
                                            src={
                                              (process.env.UPLOAD_FOLDER_URL ??
                                                "http://localhost:5555/uploads") +
                                              data.file?.filePath
                                            }
                                            alt="image"
                                          />
                                          <div className={styles.imageTime}>
                                            <small>
                                              {formatDate(data.createdTime)}
                                            </small>
                                            <small className="ms-1 text-white">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </small>
                                          </div>
                                          <div
                                            className={styles.gifContextMenu}
                                            onClick={() => {
                                              saveGif(data.file);
                                            }}
                                          >
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-download"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                              </svg>
                                            </span>
                                            <span className="ms-1">
                                              Lưu gif
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              <div
                                className={
                                  "rounded-circle align-self-end cursor-pointer " +
                                  styles.avatar
                                }
                                onClick={() => {
                                  showUserInfo(data.user);
                                }}
                              >
                                <img
                                  className="rounded-circle"
                                  width={30}
                                  height={30}
                                  src={
                                    data.user?.avatar
                                      ? (process.env.UPLOAD_FOLDER_URL ??
                                          "http://localhost:5555/uploads") +
                                        data.user?.avatar
                                      : defaultAvatar
                                  }
                                  alt={data.user?.fullName ?? "user-avatar"}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={styles.rightMessageItem}>
                              <div className={styles.overlayTrigger}>
                                <OverlayTrigger
                                  trigger={"click"}
                                  placement="left"
                                  rootClose //hide when click outside
                                  overlay={
                                    <Popover>
                                      <Popover.Body className="d-flex pt-1 pb-2 ps-3 pe-3">
                                        <div
                                          className="text-danger cursor-pointer"
                                          title="Xóa"
                                          onClick={() => {
                                            document.body.click();
                                            deleteMessage(data);
                                          }}
                                        >
                                          Xóa, gỡ bỏ
                                        </div>
                                        {data.type === MessageTypes.MESSAGE &&
                                        data.forwardedFrom === undefined ? (
                                          <div
                                            className="ms-3 text-success cursor-pointer"
                                            title="Sửa"
                                            onClick={() => {
                                              document.body.click();
                                              clickEditButton(data);
                                            }}
                                          >
                                            Sửa
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div
                                          className="ms-3 text-primary cursor-pointer"
                                          title="Chuyển tiếp"
                                          onClick={() => {
                                            document.body.click();
                                            forwardMessage(data);
                                          }}
                                        >
                                          Chuyển tiếp
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <div
                                    className={styles.expandActions + " me-1"}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      className="bi bi-three-dots"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                    </svg>
                                  </div>
                                </OverlayTrigger>
                                <div
                                  className={styles.replyButton + " me-2"}
                                  title="Trả lời"
                                  onClick={() => {
                                    clickReplyButton(data);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-reply-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                  </svg>
                                </div>
                              </div>
                              {data.type === MessageTypes.MESSAGE ? (
                                <div
                                  className={
                                    styles.message + " " + styles.fromMe
                                  }
                                  ref={newMessageRef}
                                >
                                  {data.forwardedFrom ? (
                                    <div className="allow-one-line text-success">
                                      Chuyển tiếp từ{" "}
                                      <span
                                        className="cursor-pointer fw-bold"
                                        onClick={() => {
                                          showUserInfo(data.forwardedFrom);
                                        }}
                                      >
                                        {data.forwardedFrom.fullName}
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      {messages[index - 1]?.user?._id !==
                                      data.user._id ? (
                                        <div
                                          className="allow-one-line text-success cursor-pointer fw-bold"
                                          onClick={() => {
                                            showUserInfo(data.user);
                                          }}
                                        >
                                          {data.user.fullName}
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <div className={styles.messageText}>
                                    {data.message}
                                  </div>
                                  <div className="text-end text-muted">
                                    {data.lastModified &&
                                    data.lastModified > data.createdTime ? (
                                      <>
                                        <small>(Đã sửa)</small>
                                        <small className="ms-1">
                                          {formatDate(data.lastModified)}
                                        </small>
                                      </>
                                    ) : (
                                      <small className="ms-1">
                                        {formatDate(data.createdTime)}
                                      </small>
                                    )}
                                    <small className="ms-1 text-success">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="currentColor"
                                        className="bi bi-check2"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                      </svg>
                                    </small>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {data.type === MessageTypes.IMAGE ? (
                                    <div className={styles.image}>
                                      {data.forwardedFrom ? (
                                        <>
                                          <div
                                            className={
                                              styles.forwardedImageFromMe +
                                              " allow-one-line"
                                            }
                                          >
                                            Chuyển tiếp từ{" "}
                                            <span
                                              className="cursor-pointer fw-bold"
                                              onClick={() => {
                                                showUserInfo(
                                                  data.forwardedFrom
                                                );
                                              }}
                                            >
                                              {data.forwardedFrom.fullName}
                                            </span>
                                          </div>
                                          <img
                                            className="w-100 cursor-pointer"
                                            src={
                                              (process.env.UPLOAD_FOLDER_URL ??
                                                "http://localhost:5555/uploads") +
                                              data.file?.filePath
                                            }
                                            alt="image"
                                            onClick={() =>
                                              zoomInImage(
                                                (process.env
                                                  .UPLOAD_FOLDER_URL ??
                                                  "http://localhost:5555/uploads") +
                                                  data.file?.filePath
                                              )
                                            }
                                          />
                                          <div className={styles.imageTime}>
                                            <small>
                                              {formatDate(data.createdTime)}
                                            </small>
                                            <small className="ms-1 text-white">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </small>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="w-100 cursor-pointer"
                                            src={
                                              (process.env.UPLOAD_FOLDER_URL ??
                                                "http://localhost:5555/uploads") +
                                              data.file?.filePath
                                            }
                                            alt="image"
                                            onClick={() =>
                                              zoomInImage(
                                                (process.env
                                                  .UPLOAD_FOLDER_URL ??
                                                  "http://localhost:5555/uploads") +
                                                  data.file?.filePath
                                              )
                                            }
                                          />
                                          <div className={styles.imageTime}>
                                            <small>
                                              {formatDate(data.createdTime)}
                                            </small>
                                            <small className="ms-1 text-white">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </small>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {data.type === MessageTypes.VIDEO ? (
                                        <div>
                                          <video>
                                            <source />
                                          </video>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.image + " " + styles.gif
                                          }
                                        >
                                          {data.forwardedFrom ? (
                                            <>
                                              <div
                                                className={
                                                  styles.forwardedImageFromMe +
                                                  " allow-one-line"
                                                }
                                              >
                                                Chuyển tiếp từ{" "}
                                                <span
                                                  className="cursor-pointer fw-bold"
                                                  onClick={() => {
                                                    showUserInfo(
                                                      data.forwardedFrom
                                                    );
                                                  }}
                                                >
                                                  {data.forwardedFrom.fullName}
                                                </span>
                                              </div>
                                              <div className="position-relative">
                                                <img
                                                  className="w-100"
                                                  src={
                                                    (process.env
                                                      .UPLOAD_FOLDER_URL ??
                                                      "http://localhost:5555/uploads") +
                                                    data.file?.filePath
                                                  }
                                                  alt="image"
                                                />
                                                <div
                                                  className={styles.imageTime}
                                                >
                                                  <small>
                                                    {formatDate(
                                                      data.createdTime
                                                    )}
                                                  </small>
                                                  <small className="ms-1 text-white">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="12"
                                                      height="12"
                                                      fill="currentColor"
                                                      className="bi bi-check2"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                    </svg>
                                                  </small>
                                                </div>
                                                <div
                                                  className={
                                                    styles.gifContextMenu
                                                  }
                                                  onClick={() => {
                                                    saveGif(data.file);
                                                  }}
                                                >
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      className="bi bi-download"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                    </svg>
                                                  </span>
                                                  <span className="ms-1">
                                                    Lưu gif
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="w-100"
                                                src={
                                                  (process.env
                                                    .UPLOAD_FOLDER_URL ??
                                                    "http://localhost:5555/uploads") +
                                                  data.file?.filePath
                                                }
                                                alt="image"
                                              />
                                              <div className={styles.imageTime}>
                                                <small>
                                                  {formatDate(data.createdTime)}
                                                </small>
                                                <small className="ms-1 text-white">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill="currentColor"
                                                    className="bi bi-check2"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                  </svg>
                                                </small>
                                              </div>
                                              <div
                                                className={
                                                  styles.gifContextMenu
                                                }
                                                onClick={() => {
                                                  saveGif(data.file);
                                                }}
                                              >
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-download"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                  </svg>
                                                </span>
                                                <span className="ms-1">
                                                  Lưu gif
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              <div
                                className={
                                  "rounded-circle align-self-end cursor-pointer " +
                                  styles.avatar
                                }
                                onClick={() => {
                                  showUserInfo(data.user);
                                }}
                              >
                                <img
                                  className="rounded-circle"
                                  width={30}
                                  height={30}
                                  src={
                                    data.user?.avatar
                                      ? (process.env.UPLOAD_FOLDER_URL ??
                                          "http://localhost:5555/uploads") +
                                        data.user?.avatar
                                      : defaultAvatar
                                  }
                                  alt={data.user?.fullName ?? "user-avatar"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      //From them messages
                      <>
                        {data.replyTo ? (
                          <>
                            <div className={styles.leftMessageItem}>
                              <div
                                className={
                                  "rounded-circle align-self-end cursor-pointer " +
                                  styles.avatar
                                }
                                onClick={() => {
                                  showUserInfo(data.user);
                                }}
                              >
                                <img
                                  className="rounded-circle"
                                  width={30}
                                  height={30}
                                  src={
                                    data.user?.avatar
                                      ? (process.env.UPLOAD_FOLDER_URL ??
                                          "http://localhost:5555/uploads") +
                                        data.user?.avatar
                                      : defaultAvatar
                                  }
                                  alt={data.user?.fullName ?? "user-avatar"}
                                />
                              </div>

                              {data.type === MessageTypes.MESSAGE ? (
                                <div
                                  className={
                                    styles.message + " " + styles.fromThem
                                  }
                                  ref={newMessageRef}
                                >
                                  {messages[index - 1]?.user?._id !==
                                  data.user?._id ? (
                                    <div
                                      className="allow-one-line text-success cursor-pointer fw-bold mb-1"
                                      onClick={() => {
                                        showUserInfo(data.user);
                                      }}
                                    >
                                      {data.user?.fullName}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    className="border-start ps-1 mb-2 border-2 border-success cursor-pointer"
                                    onClick={() =>
                                      jumpToMessage(data.replyTo?._id)
                                    }
                                  >
                                    <small className="d-block allow-one-line">
                                      {data.replyTo.user?.fullName}
                                    </small>
                                    {data.replyTo.type ===
                                    MessageTypes.MESSAGE ? (
                                      <small className="d-block allow-one-line text-muted">
                                        {data.replyTo.message}
                                      </small>
                                    ) : (
                                      <small className="d-block allow-one-line text-muted">
                                        Hình ảnh
                                      </small>
                                    )}
                                  </div>
                                  <div>
                                    <div className={styles.messageText}>
                                      {data.message}
                                    </div>
                                    <div className="text-end text-muted">
                                      {data.lastModified &&
                                      data.lastModified > data.createdTime ? (
                                        <>
                                          <small>(Đã sửa)</small>
                                          <small className="ms-1">
                                            {formatDate(data.lastModified)}
                                          </small>
                                        </>
                                      ) : (
                                        <small className="ms-1">
                                          {formatDate(data.createdTime)}
                                        </small>
                                      )}
                                      <small className="ms-1 text-success">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="currentColor"
                                          className="bi bi-check2"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {data.type === MessageTypes.IMAGE ? (
                                    <div
                                      className={styles.image}
                                      onClick={() =>
                                        zoomInImage(
                                          (process.env.UPLOAD_FOLDER_URL ??
                                            "http://localhost:5555/uploads") +
                                            data.file?.filePath
                                        )
                                      }
                                    >
                                      <img
                                        className="w-100"
                                        src={
                                          (process.env.UPLOAD_FOLDER_URL ??
                                            "http://localhost:5555/uploads") +
                                          data.file?.filePath
                                        }
                                        alt="image"
                                      />
                                      <div className={styles.imageTime}>
                                        <small>
                                          {formatDate(data.createdTime)}
                                        </small>
                                        <small className="ms-1 text-white">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            fill="currentColor"
                                            className="bi bi-check2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                          </svg>
                                        </small>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {data.type === MessageTypes.VIDEO ? (
                                        <div>
                                          <video>
                                            <source />
                                          </video>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.image + " " + styles.gif
                                          }
                                        >
                                          <img
                                            className="w-100"
                                            src={
                                              (process.env.UPLOAD_FOLDER_URL ??
                                                "http://localhost:5555/uploads") +
                                              data.file?.filePath
                                            }
                                            alt="image"
                                          />
                                          <div className={styles.imageTime}>
                                            <small>
                                              {formatDate(data.createdTime)}
                                            </small>
                                            <small className="ms-1 text-white">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </small>
                                          </div>
                                          <div
                                            className={styles.gifContextMenu}
                                            onClick={() => {
                                              saveGif(data.file);
                                            }}
                                          >
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-download"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                              </svg>
                                            </span>
                                            <span className="ms-1">
                                              Lưu gif
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              <div className={styles.overlayTrigger}>
                                <div
                                  className={styles.replyButton + " ms-2"}
                                  title="Trả lời"
                                  onClick={() => {
                                    clickReplyButton(data);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-reply-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                  </svg>
                                </div>
                                <OverlayTrigger
                                  trigger={"click"}
                                  placement="right"
                                  rootClose //hide when click outside
                                  overlay={
                                    <Popover>
                                      <Popover.Body className="d-flex pt-1 pb-2 ps-3 pe-3">
                                        {checkRoomPermission(
                                          room.canDeleteMessage,
                                          room.roomOwner
                                        ) ? (
                                          <>
                                            <div
                                              className="text-danger cursor-pointer"
                                              title="Xóa"
                                              onClick={() => {
                                                document.body.click();
                                                deleteMessage(data);
                                              }}
                                            >
                                              Xóa, gỡ bỏ
                                            </div>
                                            <div
                                              className="ms-3 text-success cursor-pointer"
                                              title="Sửa"
                                              onClick={() => {
                                                document.body.click();
                                                clickEditButton(data);
                                              }}
                                            >
                                              Sửa
                                            </div>
                                            <div
                                              className="ms-3 text-primary cursor-pointer"
                                              title="Chuyển tiếp"
                                              onClick={() => {
                                                document.body.click();
                                                forwardMessage(data);
                                              }}
                                            >
                                              Chuyển tiếp
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className="text-primary cursor-pointer"
                                            title="Chuyển tiếp"
                                            onClick={() => {
                                              document.body.click();
                                              forwardMessage(data);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-forward-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="m9.77 12.11 4.012-2.953a.647.647 0 0 0 0-1.114L9.771 5.09a.644.644 0 0 0-.971.557V6.65H2v3.9h6.8v1.003c0 .505.545.808.97.557z" />
                                            </svg>
                                          </div>
                                        )}
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <div
                                    className={styles.expandActions + " ms-1"}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      className="bi bi-three-dots"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                    </svg>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={styles.leftMessageItem}>
                              <div
                                className={
                                  "rounded-circle align-self-end cursor-pointer " +
                                  styles.avatar
                                }
                                onClick={() => {
                                  showUserInfo(data.user);
                                }}
                              >
                                <img
                                  className="rounded-circle"
                                  width={30}
                                  height={30}
                                  src={
                                    data.user?.avatar
                                      ? (process.env.UPLOAD_FOLDER_URL ??
                                          "http://localhost:5555/uploads") +
                                        data.user?.avatar
                                      : defaultAvatar
                                  }
                                  alt={data.user?.fullName ?? "user-avatar"}
                                />
                              </div>

                              {data.type === MessageTypes.MESSAGE ? (
                                <div
                                  className={
                                    styles.message + " " + styles.fromThem
                                  }
                                  ref={newMessageRef}
                                >
                                  {data.forwardedFrom ? (
                                    <div className="allow-one-line text-success">
                                      Chuyển tiếp từ{" "}
                                      <span
                                        className="cursor-pointer fw-bold"
                                        onClick={() => {
                                          showUserInfo(data.forwardedFrom);
                                        }}
                                      >
                                        {data.forwardedFrom.fullName}
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      {messages[index - 1]?.user?._id !==
                                      data.user?._id ? (
                                        <div
                                          className="allow-one-line text-success cursor-pointer fw-bold"
                                          onClick={() => {
                                            showUserInfo(data.user);
                                          }}
                                        >
                                          {data.user?.fullName}
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <div className={styles.messageText}>
                                    {data.message}
                                  </div>
                                  <div className="text-end text-muted">
                                    {data.lastModified &&
                                    data.lastModified > data.createdTime ? (
                                      <>
                                        <small>(Đã sửa)</small>
                                        <small className="ms-1">
                                          {formatDate(data.lastModified)}
                                        </small>
                                      </>
                                    ) : (
                                      <small className="ms-1">
                                        {formatDate(data.createdTime)}
                                      </small>
                                    )}
                                    <small className="ms-1 text-success">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="currentColor"
                                        className="bi bi-check2"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                      </svg>
                                    </small>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {data.type === MessageTypes.IMAGE ? (
                                    <div className={styles.image}>
                                      {data.forwardedFrom ? (
                                        <>
                                          <div
                                            className={
                                              styles.forwardedImageFromThem +
                                              " allow-one-line"
                                            }
                                          >
                                            Chuyển tiếp từ{" "}
                                            <span
                                              className="cursor-pointer fw-bold"
                                              onClick={() => {
                                                showUserInfo(
                                                  data.forwardedFrom
                                                );
                                              }}
                                            >
                                              {data.forwardedFrom.fullName}
                                            </span>
                                          </div>
                                          <img
                                            className="w-100 cursor-pointer"
                                            src={
                                              (process.env.UPLOAD_FOLDER_URL ??
                                                "http://localhost:5555/uploads") +
                                              data.file?.filePath
                                            }
                                            alt="image"
                                            onClick={() =>
                                              zoomInImage(
                                                (process.env
                                                  .UPLOAD_FOLDER_URL ??
                                                  "http://localhost:5555/uploads") +
                                                  data.file?.filePath
                                              )
                                            }
                                          />
                                          <div className={styles.imageTime}>
                                            <small>
                                              {formatDate(data.createdTime)}
                                            </small>
                                            <small className="ms-1 text-white">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </small>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="w-100 cursor-pointer"
                                            src={
                                              (process.env.UPLOAD_FOLDER_URL ??
                                                "http://localhost:5555/uploads") +
                                              data.file?.filePath
                                            }
                                            alt="image"
                                            onClick={() =>
                                              zoomInImage(
                                                (process.env
                                                  .UPLOAD_FOLDER_URL ??
                                                  "http://localhost:5555/uploads") +
                                                  data.file?.filePath
                                              )
                                            }
                                          />
                                          <div className={styles.imageTime}>
                                            <small>
                                              {formatDate(data.createdTime)}
                                            </small>
                                            <small className="ms-1 text-white">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </small>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {data.type === MessageTypes.VIDEO ? (
                                        <div>
                                          <video>
                                            <source />
                                          </video>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            styles.image + " " + styles.gif
                                          }
                                        >
                                          {data.forwardedFrom ? (
                                            <>
                                              <div
                                                className={
                                                  styles.forwardedImageFromThem +
                                                  " allow-one-line"
                                                }
                                              >
                                                Chuyển tiếp từ{" "}
                                                <span
                                                  className="cursor-pointer fw-bold"
                                                  onClick={() => {
                                                    showUserInfo(
                                                      data.forwardedFrom
                                                    );
                                                  }}
                                                >
                                                  {data.forwardedFrom.fullName}
                                                </span>
                                              </div>
                                              <div className="position-relative">
                                                <img
                                                  className="w-100"
                                                  src={
                                                    (process.env
                                                      .UPLOAD_FOLDER_URL ??
                                                      "http://localhost:5555/uploads") +
                                                    data.file?.filePath
                                                  }
                                                  alt="image"
                                                />
                                                <div
                                                  className={styles.imageTime}
                                                >
                                                  <small>
                                                    {formatDate(
                                                      data.createdTime
                                                    )}
                                                  </small>
                                                  <small className="ms-1 text-white">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="12"
                                                      height="12"
                                                      fill="currentColor"
                                                      className="bi bi-check2"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                    </svg>
                                                  </small>
                                                </div>
                                                <div
                                                  className={
                                                    styles.gifContextMenu
                                                  }
                                                  onClick={() => {
                                                    saveGif(data.file);
                                                  }}
                                                >
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      className="bi bi-download"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                    </svg>
                                                  </span>
                                                  <span className="ms-1">
                                                    Lưu gif
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="w-100"
                                                src={
                                                  (process.env
                                                    .UPLOAD_FOLDER_URL ??
                                                    "http://localhost:5555/uploads") +
                                                  data.file?.filePath
                                                }
                                                alt="image"
                                              />
                                              <div className={styles.imageTime}>
                                                <small>
                                                  {formatDate(data.createdTime)}
                                                </small>
                                                <small className="ms-1 text-white">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill="currentColor"
                                                    className="bi bi-check2"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                  </svg>
                                                </small>
                                              </div>
                                              <div
                                                className={
                                                  styles.gifContextMenu
                                                }
                                                onClick={() => {
                                                  saveGif(data.file);
                                                }}
                                              >
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-download"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                  </svg>
                                                </span>
                                                <span className="ms-1">
                                                  Lưu gif
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              <div className={styles.overlayTrigger}>
                                <div
                                  className={styles.replyButton + " ms-2"}
                                  title="Trả lời"
                                  onClick={() => {
                                    clickReplyButton(data);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-reply-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                  </svg>
                                </div>
                                <OverlayTrigger
                                  trigger={"click"}
                                  placement="right"
                                  rootClose //hide when click outside
                                  overlay={
                                    <Popover>
                                      <Popover.Body className="d-flex pt-1 pb-2 ps-3 pe-3">
                                        {checkRoomPermission(
                                          room.canDeleteMessage,
                                          room.roomOwner
                                        ) ? (
                                          <>
                                            <div
                                              className="text-danger cursor-pointer"
                                              title="Xóa"
                                              onClick={() => {
                                                document.body.click();
                                                deleteMessage(data);
                                              }}
                                            >
                                              Xóa, gỡ bỏ
                                            </div>
                                            <div
                                              className="ms-3 text-success cursor-pointer"
                                              title="Sửa"
                                              onClick={() => {
                                                document.body.click();
                                                clickEditButton(data);
                                              }}
                                            >
                                              Sửa
                                            </div>
                                            <div
                                              className="ms-3 text-primary cursor-pointer"
                                              title="Chuyển tiếp"
                                              onClick={() => {
                                                document.body.click();
                                                forwardMessage(data);
                                              }}
                                            >
                                              Chuyển tiếp
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className="text-primary cursor-pointer"
                                            title="Chuyển tiếp"
                                            onClick={() => {
                                              document.body.click();
                                              forwardMessage(data);
                                            }}
                                          >
                                            Chuyển tiếp
                                          </div>
                                        )}
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <div
                                    className={styles.expandActions + " ms-1"}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      className="bi bi-three-dots"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                    </svg>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )
              )}
            </div>
            {showScrollToBottomButton === true ? (
              <div className={styles.scrollToBottomButtonArea}>
                <div className="text-secondary me-2 pt-2 fw-bold">
                  (Tin nhắn mới)
                </div>
                <div
                  className={
                    "p-2 text-secondary bg-light rounded-circle " +
                    styles.scrollToBottomButton
                  }
                  onClick={scrollToNewMessage}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className="text-center bg-secondary text-white pt-2 pb-2">
            Không có dữ liệu!
          </div>
        )}
        {/* {isShowLoading === true ? <Loading /> : <></>} */}
      </div>

      <Modal
        show={isShowImageZoom}
        onHide={() => {
          setIsShowImageZoom(false);
        }}
        backdrop={true}
        keyboard={false}
        size="lg"
      >
        <Modal.Body className="p-0 m-0">
          <div className="w-100">
            <img
              className="w-100"
              src={zoomedInImageURL}
              alt="zoomed in image"
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isShowForwardModal}
        onHide={() => {
          setIsShowForwardModal(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <ForwardingMessage
            user={user}
            socket={socket}
            setIsShowForwardModal={setIsShowForwardModal}
          />
        </Modal.Body>
        <ModalFooter>
          <input
            className="btn btn-secondary"
            type="button"
            value="Đóng"
            onClick={() => {
              setIsShowForwardModal(false);
            }}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
