import { Modal, Form, Input, notification } from "antd";
import {
  createRoom,
  insertRole,
  updateRole,
  updateRoom,
} from "../../../helpers/helper";
import { useEffect } from "react";

const ModalAddEdit = ({ isOpen, onClose, selectedRoom }) => {
  console.log(selectedRoom);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const handleOk = async () => {
    if (!selectedRoom?._id) {
      try {
        const result = await createRoom({
          roomName: form.getFieldValue("roomName"),
        });
        api["success"]({
          message: "Tạo thành công",
          description: "Tạo quyền hạn thành công",
        });
        onClose();
      } catch (error) {
        return api["error"]({
          message: "Lỗi",
          description: error.response?.data?.message,
        });
      }
    } else {
      try {
        const result = await updateRoom(selectedRoom?._id, {
          roomName: form.getFieldValue("roomName"),
        });
        api["success"]({
          message: "Cập nhật thành công",
          description: "Cập nhật quyền hạn thành công",
        });
        onClose();
      } catch (error) {
        return api["error"]({
          message: "Lỗi",
          description: error.response?.data?.message,
        });
      }
    }
  };

  useEffect(() => {
    if (selectedRoom) {
      form.setFieldValue("roomName", selectedRoom?.roomName);
    }
  }, [selectedRoom]);

  return (
    <Modal open={isOpen} onCancel={onClose} onOk={handleOk}>
      {contextHolder}
      <Form form={form} style={{ margin: "20px 10px" }}>
        <Form.Item name="roomName" label="Tên phòng chat">
          <Input placeholder="Nhập tên phòng chat" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddEdit;
