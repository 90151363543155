import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import styles from "../../assets/styles/ForwardingMessage.module.css";
import defaultGroup from "../../assets/images/default-group.webp";
import defaultAvatar from "../../assets/images/default-avatar.webp";
import { RoomTypes } from "../../common/const";

export default function ForwardingMessage({
  user,
  socket,
  setIsShowForwardModal,
}) {
  const dispatch = useDispatch();
  const [forwardingMessageState, setForwardingMessage] = useState(null);
  const [rooms, setRooms] = useState(new Array());
  const [onlineMembers, setOnlineMembers] = useState(new Array());

  const storedForwardingMessage = useSelector(
    (state) => state.forwardingMessage
  );

  useEffect(() => {
    setForwardingMessage(storedForwardingMessage);
  }, [storedForwardingMessage]);

  useEffect(() => {
    if (user._id) {
      axios.get(`/api/room/getAllByUser`).then((response) => {
        if (response.status === 1) {
          setRooms(response.data?.filter((item) => item?._id));
        }
      });
    }
  }, [user]);

  const onlineUsers = useSelector((state) => state.onlineUsers);
  useEffect(() => {
    setOnlineMembers(onlineUsers);
  }, [onlineUsers]);

  async function forwardMessage(room) {
    let dataToInsert = {
      user: user._id,
      message: forwardingMessageState.message,
      room: room._id,
      type: forwardingMessageState.type,
      forwardedFrom: forwardingMessageState.user?._id,
      file: forwardingMessageState.file?._id,
    };

    let response = await axios.post("/api/chat/insert", dataToInsert);
    console.log('sdaasdasd', response)
    socket.emit("send_message", response.data.data);
    setTimeout(() => {
      // const action = setClickedRoom(room);
      // dispatch(action);
      setIsShowForwardModal(false);
    }, 300);
  }

  return (
    <div className={styles.rooms}>
      {rooms.map((room) => (
        <div
          className={"d-flex p-2 " + styles.roomItem}
          key={room._id}
          onClick={() => {
            forwardMessage(room);
          }}
        >
          {room.roomType === RoomTypes.PERSONAL ? (
            <>
              <div className="rounded-circle">
                <img
                  className="rounded-circle"
                  width={50}
                  height={50}
                  src={
                    room.members.filter((x) => x.user._id !== user._id)[0].user
                      .avatar
                      ? process.env.UPLOAD_FOLDER_URL +
                        room.members.filter((x) => x.user._id !== user._id)[0]
                          .user.avatar
                      : defaultAvatar
                  }
                  alt={room.roomName ?? "room-icon"}
                />
              </div>
              <div className="ms-2">
                <div className="allow-one-line fw-bold">
                  {
                    room.members.filter((x) => x.user._id !== user._id)[0].user
                      .fullName
                  }
                </div>
                {onlineMembers.filter(
                  (x) =>
                    x.user?._id ===
                    room?.members?.filter((x) => x.user._id !== user._id)[0]
                      .user._id
                ).length > 0 ? (
                  <small className="text-primary d-block">online</small>
                ) : (
                  <small className="text-muted d-block">offline</small>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="rounded-circle">
                {room.roomIcon ? (
                  <img
                    width={50}
                    height={50}
                    className="rounded-circle"
                    src={process.env.UPLOAD_FOLDER_URL + room.roomIcon}
                    alt={room.roomName}
                  />
                ) : (
                  <img
                    width={50}
                    height={50}
                    className="rounded-circle"
                    src={process.env.DEFAULT_GROUP_ICON_URL ?? defaultGroup}
                    alt={room.roomName}
                  />
                )}
              </div>
              <div className="ms-2">
                <div className="allow-one-line fw-bold">{room?.roomName}</div>
                <div>
                  (
                  {
                    room?.members?.filter((x) => x.user?.userName !== "admin")
                      .length
                  }{" "}
                  thành viên)
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
