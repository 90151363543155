export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function ellipsisMiddle(text, suffixCount) {
  return text.length > suffixCount
    ? text.slice(0, suffixCount).trim() + "..."
    : text;
}

export function formatDate(date) {
  if (date) {
    let newDate = new Date(date);
    let now = new Date();
    let days = newDate.getDate();
    if (now.getDate() - days <= 1) {
      let hours = newDate.getHours();
      let minutes = newDate.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      let strMinutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + strMinutes + " " + ampm;
      return strTime;
    } else if (1 < now.getDate() - days && now.getDate() - days < 8) {
      if (newDate.getDay() === 0) {
        return "Sun";
      }
      if (newDate.getDay() === 1) {
        return "Mon";
      }
      if (newDate.getDay() === 2) {
        return "Tue";
      }
      if (newDate.getDay() === 3) {
        return "Wed";
      }
      if (newDate.getDay() === 4) {
        return "Thu";
      }
      if (newDate.getDay() === 5) {
        return "Fri";
      }
      if (newDate.getDay() === 6) {
        return "Sat";
      }
    } else {
      var mm = newDate.getMonth() + 1;
      var dd = newDate.getDate();

      return [
        (dd > 9 ? "" : "0") + dd,
        (mm > 9 ? "" : "0") + mm,
        newDate.getFullYear(),
      ].join("/");
    }
  } else {
    return null;
  }
}
