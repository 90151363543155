import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Input, Col, Row, notification, Typography } from "antd";
import { login } from "../../helpers/helper";
import img_login from "../../assets/images/auth-side-bg.jpg";
import bg from "../../assets/images/cover-pattern.png";
import logo from "../../assets/images/taipei101.png";

const { Title } = Typography;

export default function LoginPage() {
  document.title = "Đăng nhập";

  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const _req = {
      userName: values.username,
      password: values.password,
    };

    setLoading(true);

    const _res = await login(_req);
    if (_res?.data === null) {
      setLoading(false);
      return api["error"]({
        message: "Lỗi",
        description: `${_res?.message}`,
      });
    }

    if (_res?.status === 1) {
      setLoading(false);
      sessionStorage.setItem("token", _res?.data?.token);
      sessionStorage.setItem("infoUsers", JSON.stringify(_res.data));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + _res.data?.token.replace(/"/g, "");

      return window.location.replace("/");
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}

      <Row
        // className="container-login"
        align="middle"
        justify="space-between"
      >
        <Col span={8}>
          <img src={img_login} alt="" />
        </Col>
        <Col span={16}>
          <div className="d-flex items-center justify-content-center">
            <div style={{ width: "400px" }}>
              <div style={{ fontWeight: "500", fontSize: "20px" }}>
                Welcome back!
              </div>
              <div className="mt-2 mb-4">
                Please enter your credentials to sign in!
              </div>
              <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  style={{ fontWeight: "500" }}
                  label="Tên đăng nhập"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên đăng nhập!",
                    },
                  ]}
                >
                  <Input className="p-2" />
                </Form.Item>
                <Form.Item
                  style={{ fontWeight: "500" }}
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu!",
                    },
                  ]}
                >
                  <Input.Password className="p-2" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading ? true : false}
                    style={{ width: "100%", fontWeight: "500", height: "40px" }}
                  >
                    ĐĂNG NHẬP
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
