import { configureStore } from "@reduxjs/toolkit";
import onlineUsersReducer from './users/onlineUsersSlice';
import userInfoReducer from './users/userInfoSlice';
import clickedRoomReducer from './rooms/clickedRoomSlice';
import logedInUserReducer from './users/logedInUserSlice';
import gifsReducer from './gifs/gifsSlice';
import forwardingMessageReducer from './messages/forwardingMessageSlice';
import setSearchingMessage from "./messages/searchingMessageSlice";

const rootReducer = {
    onlineUsers: onlineUsersReducer,
    userInfo: userInfoReducer,
    clickedRoom: clickedRoomReducer,
    logedInUser: logedInUserReducer,
    gifs: gifsReducer,
    forwardingMessage: forwardingMessageReducer,
    searchingMessage: setSearchingMessage
}

const store = configureStore({
    reducer: rootReducer
});

export default store;